import { Box, Card, IconButton, Tooltip, CircularProgress, Stack, Typography, Grid } from "@mui/material";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import SoftSelect from "components/SoftSelect";
import DataTable from "examples/Tables/DataTable";
import { green, grey, red } from "@mui/material/colors";
import {
  Delete,
  DragHandle,
  Edit,
  KeyboardArrowDown,
  KeyboardDoubleArrowUp,
  Visibility,
} from "@mui/icons-material";
import PropTypes from 'prop-types';
import useFetchTickets from "../hooks/useFetchTickets";
import moment from "moment";
import SoftInput from "components/SoftInput";
import styled from "@emotion/styled";
import SelectInfo from "../data/SelectInfo";

// Create a styled component for SoftSelect
const CustomSoftSelect = styled(SoftSelect)(({ theme }) => ({
  '& .css-l4kyc0-Control': {
    backgroundColor: '#ffffff !important',
    // Ensure to handle the states directly
    '&:hover': {
      backgroundColor: '#ffffff !important',
    },
    '&.css-l4kyc0-Control--isFocused': {
      backgroundColor: '#ffffff !important',
      borderColor: '#a0a0a0 !important',
    },
    '&.css-l4kyc0-Control--isSelected': {
      backgroundColor: '#ffffff !important',
    },
  },

}));

function HistoriqueTickets() {
  const {
    agenceID, navigate,
    currentPage, tickets,
    loading, nameTicket,
    agenceName, setAgenceName,
    handleAgenceChange,
    confirmDeleteTicket,
    responsableImage,
    responsableName,
    emailUsers,
    setFilterNumero,
    setFilterTitre,
    filterResponsable, setFilterResponsable,
    filterEtat, setFilterEtat,
    filterDepartement, setFilterDepartement,
    filterService, setFilterService,
    setFilterDateDebut,
    setFilterDateFin,
    filteredTickets, setFilteredTickets,
    departmentsByAgence,
    servicesByAgence
  } = useFetchTickets()

  const fourDaysAgo = moment().subtract(4, 'days');

  // Filter tickets based on the threshold date
  const filteredAgain = filteredTickets.filter(ticket => {
    const dateLimite = moment(ticket.dateLimite, "DD/MM/YYYY HH:mm:ss");

    // Only show tickets if the ticket is "Terminer" and dateLimite is before 4 days ago
    return ticket.etat === "Terminer" && dateLimite.isBefore(fourDaysAgo);
  });

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={3} mt="12px">
        <SoftTypography variant="h5" sx={{ fontSize: "18px" }} >
          Liste Des Agences
        </SoftTypography>
        <SoftSelect
            placeholder="Choissiser un agence"
            options={nameTicket}
            size="medium"
            value={agenceName ?{ label: agenceName, value: agenceID }: null}
            onChange={(e)=>{
              handleAgenceChange(e, "historique/listTickets")
            }}
          />
        </Grid>
      </Grid>
      <SoftTypography variant="h5" sx={{ mt:"16px" }}>
          Filter
      </SoftTypography>
      <Grid container mt="12px" spacing={1} sx={{ display: "flex", alignItems: "center" }}>
      <Grid item xs={6} md={3}>
          <SoftTypography variant="h5" sx={{ fontSize: "12px" }}>
            Numero
          </SoftTypography>
          <SoftInput
            type="text"
            placeholder="Numero de Ticket"
            onChange={(e) => {
              setFilterNumero(e.target.value)
            }}
            sx={{
              "& .MuiInputBase-root": {
                bgcolor: "grey !important", // Set the background color
              },
              "& .css-1avhgs1-MuiInputBase-root":{
                bgcolor: "red !important"
              },
            }}
          />
        </Grid> 
        <Grid item xs={6} md={3}>
          <SoftTypography variant="h5" sx={{ fontSize: "12px" }}>
            Titre
          </SoftTypography>
          <SoftInput
            placeholder="Titre de Ticket"
            variant="outlined"
            onChange={(event) => {
              setFilterTitre(event.target.value);
            }}
            sx={{
              "& .MuiInputBase-root": {
                bgcolor: "grey !important", // Set the background color
              },
              "& .css-1avhgs1-MuiInputBase-root":{
                bgcolor: "red !important"
              },
            }}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <SoftTypography variant="h5" sx={{ fontSize: "12px" }}>
            Departement
          </SoftTypography>
          <CustomSoftSelect
            options={[
              {value:"Default", label: "Default"},
              ...departmentsByAgence.map(departement => {
                return {
                  value: departement,
                  label: departement,
                }
              })
            ]}
            value={ filterDepartement !== "" && filterDepartement !== "Default" ? { label: filterDepartement, value: filterDepartement} : null }
            placeholder="Departement de Ticket"
            onChange={(e) => {
              setFilterDepartement(e.value)
            }}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <SoftTypography variant="h5" sx={{ fontSize: "12px" }}>
            Service
          </SoftTypography>
          <CustomSoftSelect
            options={[
              {value:"Default", label: "Default"},
              ...servicesByAgence.map(service => {
                return {
                  value: service,
                  label: service,
                }
              })
            ]}
            value={ filterService !== "" && filterService !== "Default" ? { label: filterService, value: filterService} : null }
            placeholder="Service de ticket"
            onChange={(e) => {
              setFilterService(e.value)
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SoftTypography variant="h5" sx={{ fontSize: "12px" }}>
            Responsable
          </SoftTypography>
          <CustomSoftSelect
            options={[
              {value:"Default", label: "Default"},
              ...emailUsers.map((emailUser)=>{
                return {
                  value: emailUser,
                  label: emailUser,
                }
              })
            ]}
            value={ filterResponsable !== "" && filterResponsable !== "Default" ? { label: filterResponsable, value: filterResponsable} : null }
            placeholder="Responsable de Ticket"
            onChange={(e) => {
              setFilterResponsable(e.value)
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} spacing={1}>
          <SoftTypography variant="h5" sx={{ fontSize: "12px" }}>
            {"Date Range : Debut -----> Fin"}
          </SoftTypography>
          <Box display="flex" alignItems="center">
            <Grid item xs={6} mr="4px">
              <SoftInput
                type="date"
                placeholder="date début"
                onChange={(e) => {
                  setFilterDateDebut(e.target.value)
                }}
              />
            </Grid>
            <Grid item xs={6} ml="4px">
              <SoftInput
                type="date"
                placeholder="date fin"
                onChange={(e) => {
                  setFilterDateFin(e.target.value)
                }}
              />
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <Card sx={{ my: 2 }}>
        {loading ? (
          <Box sx={{ mt: 13, textAlign: "center" }}>
            <CircularProgress />
          </Box>
        ) : (
          <Box>
            <Box
              display="flex"
              flexDirection={{ xs: 'column', sm: 'row' }}
              justifyContent={{ xs: 'flex-start', sm: 'space-between' }}
              alignItems={{ xs: 'flex-start', sm: 'center' }}
              p={3}
            >
              <SoftTypography >
                Historique des tickets
              </SoftTypography>
            </Box>
            <DataTable
              table={{
                columns: [
                  { Header: "Num", accessor: "num", width:"5%" },
                  { Header: "Titre", accessor: "nom", width: "10%" },
                  { Header: "Responsable", accessor: "responsable", width: "15%" },
                  { Header: "Priorité", accessor: "priorite", width: "10%" },
                  { Header: "Statut", accessor: "statut", width: "10%" },
                  { Header: "Etat", accessor: "etat", width: "10%" },
                  { Header: "Date Limite", accessor: "dateLimite", width: "15%" },
                  { Header: "Action", accessor: "action" },
                ],
                rows: filteredAgain
                        .slice((currentPage - 1) * filteredAgain.length, currentPage * filteredAgain.length)
                        .map((ticket) => ({
                  num : ticket.TiquetNum,
                  nom: ticket.title?.length > 20 ? 
                  <Tooltip title={ticket.title}>
                    <span>
                      {ticket.title.substr(0, 20) + "..."}
                    </span>
                  </Tooltip>
                    : ticket.title,
                  responsable: ticket.responsable?.length> 24 ? 
                    <Tooltip title={ticket.responsable}>
                      <span>
                        {ticket.responsable.substr(0, 20) + "..."}
                      </span>
                    </Tooltip>
                  : ticket.responsable,
                  priorite: (
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      {ticket.priorite === "Haute" ? (
                        <KeyboardDoubleArrowUp color="error" />
                      ) : ticket.priorite === "Moyenne" ? (
                        <DragHandle color="warning" />
                      ) : (
                        <KeyboardArrowDown color="info" />
                      )}
                      <Typography variant="body" sx={{ ml: "8px" }}>
                        {ticket.priorite}
                      </Typography>
                    </Box>
                  ),
                  statut: ticket.statut > 20 ? ticket.statut.substr(0,20) : ticket.statut,
                  etat: ticket.etat,
                  dateLimite: 
                  <Tooltip title={ticket.dateLimite}>
                    <span>
                      {moment(ticket.dateLimite, "DD/MM/YYYY HH:mm:ss").format("DD/MM/YYYY")}
                    </span>
                  </Tooltip>,
                  action: (
                    <Box display="flex" alignItems="center">
                      <Tooltip title="Voir Plus">
                        <IconButton
                          sx={{ "&:hover": { bgcolor: grey[200] } }}
                          onClick={() => {
                            navigate(`/reclamation/voirTicket/${ticket._id}`);
                          }}
                        >
                          <Visibility fontSize="small" color="purple" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  ),
                })),
              }}
              entriesPerPage={{ defaultValue: 7, entries: [5, 7, 10, 15, 20, 25] }}
              canSearch
            />
          </Box>
        )}
      </Card>
    </>
  );
}
HistoriqueTickets.propTypes = {
  id: PropTypes.string.isRequired,
};
export default HistoriqueTickets; 
