import React from 'react';
import { Card, CardContent, Grid, CircularProgress, Slide, Divider, Button, Stack } from '@mui/material';
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import useFetchStatut from './hooks/useFetchStatut';
import DataTable from 'examples/Tables/DataTable';
import StatutActions from './components/StatutActions';
import AddStatut from './AddStatut';
import SoftButton from 'components/SoftButton';

function StatutTable() {
  const {
    loading,
    data,
    myRef,
    handleFetch,
  } = useFetchStatut();

  return (
    <SoftBox my={3}>
      <Card>
      <SoftBox
          display="flex"
          flexDirection={{ xs: 'column', sm: 'row' }}
          justifyContent={{ xs: 'flex-start', sm: 'space-between' }}
          alignItems={{ xs: 'flex-start', sm: 'center' }}
          p={3}
        >
          <SoftBox lineHeight={1}>
            <SoftTypography variant="h5" fontWeight="medium">
              Liste des statuts
            </SoftTypography>
          </SoftBox>
          <Stack spacing={1} direction={{ xs: 'column', sm: 'row' }}>
            <AddStatut handleFetch={handleFetch} />
          </Stack>
        </SoftBox>
        
        {loading ? (
          <SoftBox sx={{ mt: 13, textAlign: 'center' }}>
            <CircularProgress />
          </SoftBox>
        ) : (
          <div>
            <Slide direction="left" in={true} mountOnEnter unmountOnExit>
              <div ref={myRef}>
                
                  <DataTable
                    table={{
                      columns: [
                        { Header: "Nom de statut", accessor: 'titre' },
                        { Header: "action", accessor: "action", width: "12%" },
                      ],
                      rows: data?.map((statut) => ({
                        titre: statut?.titre,
                        action: (
                          <StatutActions
                            key={statut?._id}
                            statut={statut}
                            handleFetch={handleFetch}
                            
                          />
                        ),
                      })),
                    }}
                    entriesPerPage={{
                      defaultValue: 7,
                      entries: [5, 7, 10, 15, 20, 25],
                    }}
                    canSearch
                  />
              
              </div>
            </Slide>
           
          </div>
        )}
      </Card>
    </SoftBox>
  );
}

export default StatutTable;
