/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Soft UI Dashboard PRO React base styles
import typography from "assets/theme/base/typography";

function configs(labels, datasets, totalData) {
  return {
    data: {
      labels,
      datasets: datasets.map((dataset) => ({
        ...dataset,
        backgroundColor: dataset.backgroundColor, // Assign the color per status
        stack: 'Stack 0', // Ensures all datasets are stacked together
      })),
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: true, // Show the legend to indicate colors for each status
        },
        tooltip: {
          callbacks: {
            // Customizing the title (header) of the tooltip
            title: function(tooltipItems) {
              // Assuming tooltipItems[0] is the hovered bar
              return `${tooltipItems[0].label}`;
            },
            // Customizing the body of the tooltip
            label: function(tooltipItem) {
              const datasetLabel = tooltipItem.dataset.label || '';
              const value = tooltipItem.raw;
              const total = totalData[tooltipItem.dataIndex] || 0;
              return `${datasetLabel}: ${value} (Total: ${total})`;
            },
            // Customizing the title (header) of the tooltip
            title: function(tooltipItems) {
              return `${tooltipItems[0].label}`
            },
            // Customizing the body of the tooltip
            label: function(tooltipItem) {
              const datasetLabel = tooltipItem.dataset.label || '';
              const value = tooltipItem.raw;
              return `${datasetLabel.split("-")[1]}: ${value}`;
            }, 
            footer: function(tooltipItems) {
              return `Total Tickets : ${totalData[tooltipItems[0].dataIndex]}`;
            },
          },
        },
      },
      scales: {
        y: {
          stacked: true, // Stack the Y axis
          grid: {
            drawBorder: false,
            display: true,
            drawOnChartArea: true,
            drawTicks: false,
            borderDash: [5, 5],
          },
          ticks: {
            display: true,
            padding: 10,
            color: "#9ca2b7",
            font: {
              size: 11,
              family: typography.fontFamily,
              style: "normal",
              lineHeight: 2,
            },
          },
        },
        x: {
          stacked: true, // Stack the X axis
          grid: {
            drawBorder: false,
            display: false,
            drawOnChartArea: true,
            drawTicks: true,
          },
          ticks: {
            display: true,
            color: "#9ca2b7",
            padding: 10,
            font: {
              size: 11,
              family: typography.fontFamily,
              style: "normal",
              lineHeight: 2,
            },
          },
        },
      },
    },
  };
}

export default configs;
