import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { SoftUIControllerProvider } from "context";
import { SocketContextProvider } from "context/SocketContext";

import { BrowserRouter } from "react-router-dom";

ReactDOM.render(
  <BrowserRouter>
  <SoftUIControllerProvider>
  <SocketContextProvider>

{/* <ContextMenuProvider>  */}
 <App />
{/* </ContextMenuProvider>  */} 
</SocketContextProvider>
    </SoftUIControllerProvider>
  </BrowserRouter>,
  document.getElementById('root')
)