
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom"; 
import Tooltip from '@mui/material/Tooltip';
import api from "utils/axios";
import { shell } from '@tauri-apps/api';
import { useSoftUIController, setUser } from "context"; 
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";
import mapChart from "assets/images/rr.png";
import EmailIcon from '@mui/icons-material/Email';
import CircularProgress from '@mui/material/CircularProgress';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import useFormState from "./hooks/useFormState";
import { useAuthentication } from "./hooks/useAuthentication";

function SignInIllustration() {
  const navigate = useNavigate();
  const [showPass, setShowPass] = useState(false);
  const [bottomAlert, setBottomAlert] = useState({ show: false, message: '' });
  const [controller, dispatch] = useSoftUIController();
  const { credentials, handleEmailInputChanges, handlePasswordInputChanges, handleEmailValidation, handlePasswordValidation } = useFormState();
  const { isLoading, setIsLoading, handleLoginDesktop, isDesktop } = useAuthentication(dispatch, setBottomAlert);
  const rememberMe = true;
  useEffect(() => {
    handleLoginDesktop();
  }, [window.process]);

  useEffect(() => {
    document.title = "se connecter";
  }, []);

  const changePasswordVisibility = () => setShowPass(prevState => !prevState);

  async function handleSignIn() {
    try {
      setIsLoading(true);
      const { data } = await api.post('/auth/login', {
        email: credentials.emailValue,
        password: credentials.passValue,
      });
      const { accessToken, refreshToken } = data.data;
      handleTokens(accessToken, refreshToken);
      const parsedData = parseJwt(accessToken);
      handleAutoRefreshToken(refreshToken);
     window.location.href = "/dashboards/default";
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  }

  function handleTokens(accessToken, refreshToken) {
    localStorage.setItem('refreshToken', refreshToken);
    if (!rememberMe) {
      sessionStorage.setItem('refreshToken', refreshToken);
    }
    localStorage.setItem('accessToken', accessToken);
  }

  function parseJwt(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
  }
  async function handleAutoRefreshToken(refreshToken) {
    const intervalId = setInterval(async () => {
      try {
        const { data } = await api.post('/auth/refresh-token', {
          refreshToken
        });
        const userInfo = parseJwt(data.accessToken);
        console.log(userInfo)
        setUser(dispatch, { info: userInfo, accessToken: data.accessToken, refreshToken: data.refreshToken });
        localStorage.setItem('accessToken', data.accessToken);
      } catch (error) {
        clearInterval(intervalId);
        if (error.code === 'ERR_BAD_REQUEST') {
          sessionStorage.removeItem('refreshToken');
          localStorage.removeItem('refreshToken');
          navigate('/');
        } else if (error.code === 'ERR_NETWORK') {
          navigate('/error-500');
        }
      }
    }, 840000);
  }

  function handleError(error) {
    if (error.code === 'ERR_BAD_REQUEST') {
      setBottomAlert({ show: true, message: error.response.data.message });
    } else if (error.code === 'ERR_NETWORK') {
      setBottomAlert({ show: true, message: "erreur côté serveur 500" });
    }
    setTimeout(() => setBottomAlert(prevState => ({ ...prevState, show: false })), 10000);
  }

  // -------------------------------------------------------------------------------------------------------------------------------------------------------------------- JSX
  return (
    <IllustrationLayout
      showDefaultNavBar={false}
      title="Accéder à votre compte ADIATE"
      description="Veuillez saisir votre adresse e-mail et votre mot de passe pour se conecter"
      illustration={{
        image: mapChart,
         title: '"Rejoignez-nous pour une expérience de réclamation améliorée"',
        description:
          "Connectez-vous pour explorer, apprendre et grandir ensemble ! Chaque connexion est une étape vers le succés.", 
      }}
    >
      <SoftBox>
        <SoftBox mb={2} id="email-box">
          <SoftInput
            success={credentials.isEmailTouched && credentials.isValidEmail}
            error={credentials.isEmailTouched && !credentials.isValidEmail}
            onChange={(e) => {
              handleEmailInputChanges(e);
              handleEmailValidation(e);
            }
            }
            type="email"
            disabled={isLoading || isDesktop()}
            placeholder="Enter your email address"
            size="large"
            icon={{ component: <EmailIcon color="purple" id="icon-id" />, direction: "right" }}
            autoComplete="username"
            id="sign-in-email-input"
          />
        </SoftBox>
        <SoftBox mb={2} id="password-box">
          <SoftInput
            disabled={isLoading || isDesktop()}
            error={credentials.isPassTouched && !credentials.isValidPass}
            onChange={(e) => {
              handlePasswordInputChanges(e);
              handlePasswordValidation(e);
            }
            }
            type={showPass ? "text" : "password"}
            placeholder="................."
            size="large"
            icon={
              {
                component: (
                  showPass
                    ?
                    <Tooltip title="Masquer le mot de passe">
                      <VisibilityIcon color="purple" onClick={changePasswordVisibility} sx={{ cursor: "pointer" }} id="visibility-icon" />
                    </Tooltip>
                    :
                    <Tooltip title="Afficher le mot de passe">
                      <VisibilityOffIcon color="purple" onClick={changePasswordVisibility} sx={{ cursor: "pointer" }} id="visibility-icon" />
                    </Tooltip>
                ), direction: "right"
              }}
            autoComplete="current-password"
            id="sign-in-password-input"
          />
        </SoftBox>
        
        <SoftBox mt={4} mb={1} display="flex" justifyContent="center" alignItems="center">
          {
            isLoading
              ? <CircularProgress />
              : <SoftButton
                onClick={ handleSignIn }
                variant="gradient"
                color="purple"
                style={{ width: '100%', height: '45px' }}
                fullWidth
                id="sign-in-button"
                disabled={!isDesktop() && !credentials.isValidEmail || !isDesktop() && !credentials.isValidPass}
              >
                {isDesktop() ? "Se connecter avec l'application de web" : "Se connecter"}
              </SoftButton>
          }
        </SoftBox>
        {bottomAlert.show && <SoftTypography fontWeight="bold" fontSize={15} style={{ textAlign: 'center' }} color="error" id="error-alert">{bottomAlert.message}</SoftTypography>
        }
        {isDesktop() && <SoftTypography fontWeight="bold" fontSize={15} style={{ textAlign: 'center' }} color="error" id="error-alert">Assurez-vous que votre navigateur par défaut est le même que celui dans lequel vous vous êtes connecté à Studiffy</SoftTypography>}
      </SoftBox>
    </IllustrationLayout>
  );
}

export default SignInIllustration;
