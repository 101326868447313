/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard PRO React example components


// Soft UI Dashboard PRO React base styles
import MiniStatisticsCard from "./components/MiniStatisticsCard";
import useReclamationGenerale from "./hooks/useReclamationGenerale";
import PieCharte from "./components/PieChart";
import BarChart from "./components/BarChart";
import useReclamationParAgence from "./hooks/useReclamationParAgence";
import useRecPrioriteParAgence from "./hooks/useRecPrioriteParAgence";
import useReturnReclamationParAgence from "./hooks/useReturnReclamationParAgence";
import DataTable from "examples/Tables/DataTable";
import { Box, Typography } from "@mui/material";
import useListUsers from "./hooks/users/useListUers";
import MultipleStatisticsCard from "./components/MultipleStatisticsCard";

// Data


function Default() {

  const {        
    periodeTotalReclamation,
    handleChangePeriodeTotalReclamation,
    totalReclamationParJour, totalReclamationParMois, totalReclamationParAnnee,
    totalReclamationAFaireParJour, totalReclamationAFaireParMois, totalReclamationAFaireParAnnee,
    totalReclamationEnCoursParJour, totalReclamationEnCoursParMois, totalReclamationEnCoursParAnnee,
    totalReclamationTerminerParJour, totalReclamationTerminerParMois, totalReclamationTerminerParAnnee,  
    tauxTotalReclamationTerminer,
  } = useReclamationGenerale();

  const {
    namesAgence,
    agenceNameChoisi, setAgenceNameChoisi,
    agenceID, setAgenceID,
    departmentsByAgence,
    departementNameChoisi, setDepartementNameChoisi,
    servicesByAgence,
    serviceNameChoisi, setServiceNameChoisi,
    choisirAnneeReclamation, setChoisirAnneeReclamation,
    choisirMoisReclamation, setChoisirMoisReclamation,
    averageResolutionTimeParAgence,
    nbReclamationRouvertParAgence,
    percentageReclamationAgenceTerminer,
    titres,
    ticketNameChoisi, setTicketNameChoisi,
    countTicketRepetedChoisi,
    idTicketsRepeter,
    dataSets,
    totalData,
    dataSetsDepSer,
    total
  } = useReturnReclamationParAgence();

  const {
    prioriteHaute,
    prioriteMoyenne,
    prioriteFaible
  } = useRecPrioriteParAgence({
    agenceID
  });

  const {
    currentPage,
    listUsers
  } = useListUsers();

  return (
    <>
      <SoftBox py={3}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={6}>
            <Grid item xs={12} mb="16px">
              <MiniStatisticsCard
                title={{ text: "Total des tickets" }}
                date={periodeTotalReclamation}
                onChangeDate={handleChangePeriodeTotalReclamation}
                count={periodeTotalReclamation === "Jour Actuel" ? totalReclamationParJour : periodeTotalReclamation === "Mois Actuel" ? totalReclamationParMois : totalReclamationParAnnee }
                icon={{ color: "info", component: "assignment" }}
              />
            </Grid>
            <Grid item xs={12} mb="16px">
              <MiniStatisticsCard
                title={{ text: "Taux total des tickets terminés" }}
                count={tauxTotalReclamationTerminer }
                icon={{ color: "info", component: "assignment" }}
              />
            </Grid>
            <Grid item xs={12} mb="16px">
              <PieCharte 
                title="Nombre total des tickets par état"
                date={periodeTotalReclamation}
                onChangeDate={handleChangePeriodeTotalReclamation}
                PieCharteData={{
                  labels: ["Total des tickets à faire" , "Total des tickets en cours", "Total des tickets terminés"],
                  datasets: {
                    backgroundColors: ["light", "success", "primary","warning", "dark", "secondary", "error", "info"],
                    data: [
                      periodeTotalReclamation === "Jour Actuel" ? totalReclamationAFaireParJour : periodeTotalReclamation === "Mois Actuel" ? totalReclamationAFaireParMois : totalReclamationAFaireParAnnee , 
                      periodeTotalReclamation === "Jour Actuel" ? totalReclamationEnCoursParJour : periodeTotalReclamation === "Mois Actuel" ? totalReclamationEnCoursParMois : totalReclamationEnCoursParAnnee , 
                      periodeTotalReclamation === "Jour Actuel" ? totalReclamationTerminerParJour : periodeTotalReclamation === "Mois Actuel" ? totalReclamationTerminerParMois : totalReclamationTerminerParAnnee , 
                    ]
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <PieCharte 
                  title="Nombre des tickets par priorité"
                  PieCharteData={{
                    labels: ["Tickets de haute priorité" , "Tickets de moyenne priorité", "Tickets de faible priorité"],
                    datasets: {
                      backgroundColors: ["error", "warning", "dark","info", "light", "success", "primary", "secondary"],
                      data: [
                        prioriteHaute,
                        prioriteMoyenne,
                        prioriteFaible,
                      ]
                    },
                  }}
                  namesAgence={namesAgence}
                  agenceNameChoisi={agenceNameChoisi}
                  setAgenceNameChoisi={setAgenceNameChoisi}
                  agenceID={agenceID}
                  setAgenceID={setAgenceID}
                />
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Grid item xs={12} mb="16px">
              <MultipleStatisticsCard
                stats={
                  [
                    {
                      icon:{ color: "success", component: "apartment" },
                      title: "Taux total des tickets terminés par agence",
                      count: percentageReclamationAgenceTerminer
                    },
                    {
                      icon:{ color: "success", component: "apartment" },
                      title: "Temps moyen de résolution des tickets",
                      count: averageResolutionTimeParAgence
                    },
                    {
                      icon:{ color: "success", component: "apartment" },
                      title: "Nombre des tickets rouverts",
                      count: nbReclamationRouvertParAgence
                    }
                  ]
                }
                bgColor="white"
                direction="right"
                namesAgence={namesAgence}
                agenceNameChoisi={agenceNameChoisi}
                setAgenceNameChoisi={setAgenceNameChoisi}
                agenceID={agenceID}
                setAgenceID={setAgenceID}

                stat2={{
                  icon:{ color: "success", component: "apartment" },
                  title: "Nombre de tickets répétés",
                  count: countTicketRepetedChoisi
                }}
                titres={titres}
                ticketNameChoisi={ticketNameChoisi}
                setTicketNameChoisi={setTicketNameChoisi}
                idTicketsRepeter={idTicketsRepeter}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} mb="16px">
            <BarChart 
              title="Nombre de tickets par agence, par département ou par service"
              verticalBarChartData={{
                labels:["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
                datasets: (departementNameChoisi !== ""  || serviceNameChoisi !== "") ? dataSetsDepSer : dataSets,
                totalData:  (departementNameChoisi !== ""  || serviceNameChoisi !== "") ? total : totalData,
              }}
              namesAgence={namesAgence}
              agenceNameChoisi={agenceNameChoisi}
              setAgenceNameChoisi={setAgenceNameChoisi}

              agenceID={agenceID}
              setAgenceID={setAgenceID}

              namesDepartement={departmentsByAgence}
              departementNameChoisi={departementNameChoisi}
              setDepartementNameChoisi={setDepartementNameChoisi}
            
              namesService={servicesByAgence}
              serviceNameChoisi={serviceNameChoisi}
              setServiceNameChoisi={setServiceNameChoisi}

              choisirAnneeReclamation={choisirAnneeReclamation}
              setChoisirAnneeReclamation={setChoisirAnneeReclamation}

              choisirMoisReclamation={choisirMoisReclamation}
              setChoisirMoisReclamation={setChoisirMoisReclamation}
              
            />
          </Grid>
          <Grid item xs={12} sx={{ borderRadius: "50px", boxShadow: "80px 80px 80px rgba(0, 0, 0, 0)" }}>
            <DataTable
              table={{
                columns: [
                  { Header: "Utilisateur", accessor: "user" },
                  { Header: "Total des tickets", accessor: "tot" },
                  { Header: "Tickets à faire", accessor: "afaire" },
                  { Header: "Tickets en cours", accessor: "encours"},
                  { Header: "Tickets terminés", accessor: "terminer" },
                  { Header: "% des tickets terminés", accessor: "pourcentage", align: "center"},
                  { Header: "Moyenne de résolution des tickets", accessor: "moyenne" },
                  
                ],
                rows: listUsers
                        .slice((currentPage - 1) * listUsers.length, currentPage * listUsers.length)
                        .map((user) => ({
                  user : <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Box sx={{ mr: "16px" }}>
                            <img src={`${process.env.REACT_APP_BASE_URL}uploads/users/${user.imageFilename}`} alt="User Image" borderRadius="50%" width="30px" height="30px" />
                          </Box>
                          <Typography variant="body" color="text">{user.name}</Typography>
                        </Box>,
                  tot: user.userStats.totalTiquets,
                  afaire: user.userStats.aFaire,
                  encours: user.userStats.enCours,
                  terminer: user.userStats.terminer,
                  pourcentage: user.userStats.percentageCompleted + "%",
                  moyenne: user.userStats.averageResolutionTime,
                })),
              }}
              entriesPerPage={{ defaultValue: 7, entries: [5, 7, 10, 15, 20, 25] }}
              canSearch
            />
          </Grid>
        </Grid>
      </SoftBox>
    </>
  );
}

export default Default;
