import React, { Suspense } from "react";
import PeopleIcon from "@mui/icons-material/People";
import LoadingAnimation from "components/LoadingAnimation";
import Default from "layouts/dashboards/default";
import PageTransition from "PageTransition";
import { Assignment, History, Shop } from "@mui/icons-material";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts"
const UsersTable = React.lazy(() => import("pages/users/UsersTable"));
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import BuildIcon from '@mui/icons-material/Build';
import ApartmentIcon from '@mui/icons-material/Apartment';
import CategoryIcon from '@mui/icons-material/Category';
import StatutTable from "pages/statut/StatutTable";
import HistoriqueTiquets from "pages/ticket/Historique/HistoriqueTiquets";

const AgenceTable = React.lazy(() => import("pages/agence/AgenceTable"));
const ServiceTable = React.lazy(() => import("pages/service/ServiceTable"));
const DepartmentTable = React.lazy(() => import("pages/department/DepartmentTable"));
const ListTickets = React.lazy(() => import("pages/ticket/ListeTicket"));
const accessToken = localStorage.getItem("accessToken");
let user = null;
if (accessToken) {
  user = JSON.parse(atob(accessToken.split(".")[1]));
}
const facility = null
const routes = [
  {
    type: "collapse",
    name: "Tableaux de bord",
    key: "dashboards",
    route: "/dashboards/default",
    icon: <Shop size="12px" />,
    component: <Default />,
  },
  {
    type: "collapse",
    name: "Utilisateurs",
    key: "users",
    route: "/users",
    component: <PageTransition><Suspense fallback={<LoadingAnimation />}><UsersTable /></Suspense></PageTransition>,
    icon: <ManageAccountsIcon size="12px" />,
  },

  {
    /* role: ["super-admin"], */
    type: "collapse",
    name: "Agences",
    key: "agences",
    route: "/agences",
    component: <PageTransition><Suspense fallback={<LoadingAnimation />}><AgenceTable /></Suspense></PageTransition>,
    icon: <AccountBalanceIcon size="12px" />,
  
  },
  {
    /* role: ["super-admin"], */
    type: "collapse",
    name: "Départements",
    key: "departements",
    route: "/departements",
    component: <PageTransition><Suspense fallback={<LoadingAnimation />}><DepartmentTable /></Suspense></PageTransition>,
    icon: <ApartmentIcon size="12px" />,
  
  },
  {
    /* role: ["super-admin"], */
    type: "collapse",
    name: "Services",
    key: "services",
    route: "/services",
    component: <PageTransition><Suspense fallback={<LoadingAnimation />}><ServiceTable /></Suspense></PageTransition>,
    icon: <BuildIcon size="12px" />,
  
  },

  {
    /* role: ["super-admin"], */
    type: "collapse",
    name: "Statut",
    key: "statut",
    route: "/statut",
    component: <PageTransition><Suspense fallback={<LoadingAnimation />}><StatutTable /></Suspense></PageTransition>,
    icon: <CategoryIcon size="12px" />,
  
  },
 

  {
    access:[],
   
    type: "collapse",
    name: "Reclamation",
    key: "reclamation",
    route:"/reclamation/listTickets",
    component: <PageTransition><Suspense fallback={<LoadingAnimation />}><ListTickets /></Suspense></PageTransition>,
    icon: <Assignment size="12px" />,
  },

  {
    access:[],
   
    type: "collapse",
    name: "Historique",
    key: "historique",
    route:"/historique/listTickets",
    component: <PageTransition><Suspense fallback={<LoadingAnimation />}><HistoriqueTiquets /></Suspense></PageTransition>,
    icon: <History size="12px" />,
  },
  
 
];
const roleMatchesHandler = (user, filteredRoute) => {
  if (user?.role === "utilisateur") {
    return !filteredRoute.role || (filteredRoute.role.includes("admin") && filteredRoute?.access?.every(accessCondition => accessCondition)) || filteredRoute.role.includes("admin");
  } else {
    return !filteredRoute.role || filteredRoute.role.includes(user.role);
  }
}

const filterRoutesByRoleAndType = (routes, user, facilityType) => {
  return routes.map(route => {
    const filteredRoute = { ...route };

    // If the route has nested routes, filter them recursively
    if (filteredRoute.collapse && filteredRoute.collapse.length > 0) {
      filteredRoute.collapse = filterRoutesByRoleAndType(filteredRoute.collapse, user, facilityType);
    }

    // Include the route if its role matches the user's role
    const roleMatches = roleMatchesHandler(user, filteredRoute);

    // Include the route if its etablisementType matches the facility type
    const typeMatches = !filteredRoute.etablisementType || filteredRoute.etablisementType.includes(facilityType);

    // Include the route if the user has the required access
    let accessMatches;
    if(user.role === "utilisateur") {
      accessMatches = !filteredRoute.access || filteredRoute.access.every(accessCondition => accessCondition);
    } else {
      accessMatches = true;
    }

    // Include the route if it has nested routes after filtering or if all conditions match
    if (filteredRoute.collapse && filteredRoute.collapse.length > 0) {
      return roleMatches && typeMatches && accessMatches ? filteredRoute : null;
    }

    // Include the route if all conditions match
    if (roleMatches && typeMatches && accessMatches) {
      return filteredRoute;
    }

    // Exclude routes where any condition doesn't match
    return null;
  }).filter(route => route !== null); // Remove null entries from the array
};
// Apply role-based and type-based filtering to the routes
const routesFiltered = user === null ? routes : filterRoutesByRoleAndType(routes, user, facility?.type);
export default routesFiltered;
