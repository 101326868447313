import { useState } from 'react';
import Swal from 'sweetalert2';
import api from 'utils/axios';

const useStatutActions = (initialStatut, handleFetch) => {
  const [open, setOpen] = useState(false);
  const [titre, setName] = useState(initialStatut.titre);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleUpdate = async () => {
    try {
      setLoading(true);
      if (titre.trim().length < 2) {
        setErrorMessage('La longueur minimale requise est de 2 caractères.');
      } else {
        const response = await api.put(`/statut/${initialStatut._id}`, { titre });
        Swal.fire('Success!', 'Mise à jour statut réussie', 'success');
        handleFetch();
        setOpen(false);
      }
    } catch (error) {
      Swal.fire('Erreur!', error.response.data.message, 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setErrorMessage('');
    setName(initialStatut.titre);
  };

  const showAlert = async () => {
    const newSwal = Swal.mixin({
      customClass: {
        confirmButton: 'button button-success',
        cancelButton: 'button button-error',
      },
      buttonsStyling: false,
    });

    newSwal.fire({
      title: 'Êtes-vous sûr(e) ?',
      text: 'Vous voulez supprimer cette statut ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui, supprimer!',
      cancelButtonText: 'Annuler',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          setLoading(true);
          const response = await api.delete(`/statut/one/${initialStatut._id}`);
          if (response) {
            (await Swal.fire("Suppression !", "statut a été supprimée.", "success"))
              .isConfirmed && handleFetch();
          }
        } catch (error) {
          Swal.fire('Erreur!', "statut n'a pas été supprimé.", 'error');
        } finally {
          setLoading(false);
        }
      }
    });
  };

  return {
    open,
    setOpen,
    titre,
    setName,
    loading,
    errorMessage,
    handleUpdate,
    handleClose,
    showAlert,
  };
};

export default useStatutActions;
