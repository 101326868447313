import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL
});

api.interceptors.request.use((config) => {

  const excludedUrls = new Set(['/auth/refresh-token', '/auth/login']);

  if (!excludedUrls.has(config.url)) {
    config.headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;
  }

  return config;
}, (error) => {
  return Promise.reject(new Error(error));
});


export default api;
