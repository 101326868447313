import { useState } from 'react';
import { emailRegex } from "utils/regex";

const useFormState = () => {
  const [credentials, setCredentials] = useState({
    emailValue: '',
    isValidEmail: false,
    isEmailTouched: false,
    passValue: '',
    isValidPass: false,
    isPassTouched: false,
  });

  const handleEmailInputChanges = (e) => {
    setCredentials((prevState) => ({
      ...prevState,
      emailValue: e.target?.value,
    }));
  };

  const handlePasswordInputChanges = (e) => {
    setCredentials((prevState) => ({
      ...prevState,
      passValue: e.target?.value,
    }));
  };

  const handleEmailValidation = () => {
    setCredentials((prevState) => ({
      ...prevState,
      isEmailTouched: true,
      isValidEmail: emailRegex.test(prevState.emailValue),
    }));
  };

  const handlePasswordValidation = () => {
    setCredentials((prevState) => ({
      ...prevState,
      isPassTouched: true,
      isValidPass: prevState.passValue.length > 7,
    }));
  };

  return {
    credentials,
    handleEmailInputChanges,
    handlePasswordInputChanges,
    handleEmailValidation,
    handlePasswordValidation,
  };
};

export default useFormState;
