import React, { useState, useEffect, lazy, Suspense } from "react";
// react-router components
import { Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";
import "./App.css";
import api from "./utils/axios";
import PageTransition from "PageTransition";
// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import outOfSideBarRoutes from "out-of-sidebar.routes";


// Soft UI Dashboard PRO React example components
import Sidenav from "layouts/Sidenav";

// Soft UI Dashboard PRO React themes
import theme from "assets/theme";


// Soft UI Dashboard PRO React routes
import routes from "routes";

// Soft UI Dashboard PRO React contexts
import { useSoftUIController, setMiniSidenav, setUser, setPurchase, setLogout } from "context";
import SignInIllustration from "pages/authentication/SignInIllustration"; // Sign In page
import 'react-phone-input-2/lib/style.css'

// Images
import brand from "assets/images/logo-ct.png";
import LoadingAnimation from "components/LoadingAnimation";
const Error500 = lazy(() => import("pages/Error500"));
const SetPassword = lazy(() => import("pages/authentication/SetPassword"));

export default function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, direction, layout, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [isAuth, setIsAuth] = useState(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);
  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    // invocing module needs to clear the saved purchases in the context api when we change the pathes
    setPurchase(dispatch, { actionType: 'clear' });
  }, [pathname]);

  const handleRefreshToken = async (refreshToken) => {
    try {
      const response = await api.post("/auth/refresh-token", {
        refreshToken 
      });
      const { refreshToken: newRefreshToken, accessToken } = response.data.data;
      const userInfo = atob(accessToken.split(".")[1]);
      setUser(dispatch, { info: JSON.parse(userInfo), accessToken, refreshToken: newRefreshToken });
      localStorage.setItem("accessToken", accessToken);
      setIsAuth(true);
      return newRefreshToken;
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const handleError = async (error) => {
    if (error.code === "ERR_BAD_REQUEST") {
      sessionStorage.removeItem("refreshToken");
      localStorage.removeItem("refreshToken");
      localStorage.clear();
      setUser(dispatch, {});
      setIsAuth(false);
      navigate("/");
    } else if (error.code === "ERR_NETWORK") {
      navigate("/error-500");
    }
  };

  useEffect(() => {
    const refreshToken = localStorage.getItem("refreshToken") || sessionStorage.getItem("refreshToken");
    if (!refreshToken) {
      setIsLoading(false);
      return;
    }
    const refreshTokenHandler = async (token) => {
      try {
        const newRefreshToken = await handleRefreshToken(token);
        const intervalId = setInterval(async () => {
          try {
            await handleRefreshToken(newRefreshToken);
          } catch {
            clearInterval(intervalId);
          }
        }, 840000);
      } catch (error) {
        await handleError(error);
      } finally {
        setIsLoading(false);
      }
    };
    refreshTokenHandler(refreshToken);
  }, []);

  routes.push(
  ...outOfSideBarRoutes
  );
  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        // Recursively call getRoutes with the updated route.collapse
        return (
          <React.Fragment key={route.key}>
            {route.route && <Route exact path={route.route} element={<PageTransition><DashboardLayout>{route.component}</DashboardLayout></PageTransition>} key={route.key} />}
            {getRoutes(route.collapse)}
          </React.Fragment>
        );
      }
      if (route.route) {
        return <Route exact path={route.route} element={
          <PageTransition> <DashboardLayout>{route.component}</DashboardLayout></PageTransition>
        } key={route.key} />;
      }
      return null;
    });
  return (() => {
    if (isLoading) {
      return <LoadingAnimation />;
    } else {
      return (
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {layout === "dashboard" && (
                < Sidenav
                  color={sidenavColor}
                  brand={brand}
                  brandName="Logiciel Ticketing Adiate"
                  routes={routes}
                  onMouseEnter={handleOnMouseEnter}
                  onMouseLeave={handleOnMouseLeave}
                />
          )}
          <Routes>
            {getRoutes(routes)}
            <Route path="/set-password" element={<Suspense fallback={<LoadingAnimation />}><SetPassword /></Suspense>} />
            <Route path="/error-500" element={<Suspense fallback={<LoadingAnimation />}><Error500 /></Suspense>} />       
            <Route
              path="/"
              element={!isAuth ? <Suspense fallback={<LoadingAnimation />}><SignInIllustration /></Suspense> : <Navigate to="/dashboards/default" />}
            />
            <Route path="*" element={<Navigate to="/dashboards/default" />} />
          </Routes>

        </ThemeProvider>

      );
    }
  })();
}
