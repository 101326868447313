import { useEffect, useState } from "react";
import api from "utils/axios";

export default function useReclamationDepartementService({ choisirAnneeReclamation, setChoisirAnneeReclamation, choisirMoisReclamation, setChoisirMoisReclamation,agenceNameChoisi, agenceID, departementNameChoisi, serviceNameChoisi}) {

    //array return le total de raclamation par agence par departement par year
    const [reclamationParAgencParDepartementeParYear, setReclamationParAgenceParDepartementParYear] = useState([]);
    const [reclamationAFaireParAgencParDepartementeParYear, setReclamationAFaireParAgenceParDepartementParYear] = useState([]);
    const [reclamationEnCoursParAgencParDepartementeParYear, setReclamationEnCoursParAgenceParDepartementParYear] = useState([]);
    const [reclamationTerminerParAgencParDepartementeParYear, setReclamationTerminerParAgenceParDepartementParYear] = useState([]);
    
    //array return le total de raclamation par agence par service par year
    const [reclamationParAgencParServiceParYear, setReclamationParAgenceParServiceParYear] = useState([]);
    const [reclamationAFaireParAgencParServiceParYear, setReclamationAFaireParAgenceParServiceParYear] = useState([]);
    const [reclamationEnCoursParAgencParServiceParYear, setReclamationEnCoursParAgenceParServiceParYear] = useState([]);
    const [reclamationTerminerParAgencParServiceParYear, setReclamationTerminerParAgenceParServiceParYear] = useState([]);
    //exemple [0,0,0,0,...] qui est [Jan, Fev, Mars, ...]

    // array return le total de reclamation par agence par departement par mois
    const [reclamationParAgencParDepartementeParMois, setReclamationParAgenceParDepartementParMois] = useState([]);
    const [reclamationAFaireParAgencParDepartementeParMois, setReclamationAFaireParAgenceParDepartementParMois] = useState([]);
    const [reclamationEnCoursParAgencParDepartementeParMois, setReclamationEnCoursParAgenceParDepartementParMois] = useState([]);
    const [reclamationTerminerParAgencParDepartementeParMois, setReclamationTerminerParAgenceParDepartementParMois] = useState([]);
    // array return le total de reclamation par agence par service par mois
    const [reclamationParAgencParServiceParMois, setReclamationParAgenceParServiceParMois] = useState([]);
    const [reclamationAFaireParAgencParServiceParMois, setReclamationAFaireParAgenceParServiceParMois] = useState([]);
    const [reclamationEnCoursParAgencParServiceParMois, setReclamationEnCoursParAgenceParServiceParMois] = useState([]);
    const [reclamationTerminerParAgencParServiceParMois, setReclamationTerminerParAgenceParServiceParMois] = useState([]);
    //exemple [0,0,0,0,...] qui est [day1, day2, day3, ...]

    const handleInformation = async () => {
        if(choisirAnneeReclamation !== undefined && choisirMoisReclamation === undefined){
            let endpoint;
            if(departementNameChoisi !== ""){
                endpoint = `kpi/getMonthlyComplainByDeptOrServiceController?agenceID=${agenceID}&year=${choisirAnneeReclamation}&entityName=${departementNameChoisi}&isDepartment=true`;
            }else if(serviceNameChoisi !== ""){
                endpoint = `kpi/getMonthlyComplainByDeptOrServiceController?agenceID=${agenceID}&year=${choisirAnneeReclamation}&entityName=${serviceNameChoisi}&isDepartment=false`;
            }
            if (departementNameChoisi !== "" || serviceNameChoisi !== "") {
                const response = await api.get(endpoint);
                const monthlyComplaints = response?.data?.monthlyComplaints;

                // Extract totals for each status category
                const months = Object.keys(monthlyComplaints);
                const total = months.map(month => monthlyComplaints[month]["total"]);
                const afaire = months.map(month => monthlyComplaints[month]["A faire"]);
                const encours = months.map(month => monthlyComplaints[month]["En cours"]);
                const terminer = months.map(month => monthlyComplaints[month]["Terminer"]);

                if (departementNameChoisi !== "") {
                    setReclamationParAgenceParDepartementParYear(total);
                    setReclamationAFaireParAgenceParDepartementParYear(afaire);
                    setReclamationEnCoursParAgenceParDepartementParYear(encours);
                    setReclamationTerminerParAgenceParDepartementParYear(terminer);
                } else if (serviceNameChoisi !== "") {
                    setReclamationParAgenceParServiceParYear(total);
                    setReclamationAFaireParAgenceParServiceParYear(afaire);
                    setReclamationEnCoursParAgenceParServiceParYear(encours);
                    setReclamationTerminerParAgenceParServiceParYear(terminer);
                }
            }

        }else if(choisirAnneeReclamation !== undefined && choisirMoisReclamation !== undefined){
            let endpoint;
            if(departementNameChoisi !== ""){
                endpoint = `kpi/getdaillyComplainsByServiceAndDepartment?agenceID=${agenceID}&year=${choisirAnneeReclamation}&month=${choisirMoisReclamation}&name=${departementNameChoisi}&isDepartment=true`;
            }else if(serviceNameChoisi !== ""){
                endpoint = `kpi/getdaillyComplainsByServiceAndDepartment?agenceID=${agenceID}&year=${choisirAnneeReclamation}&month=${choisirMoisReclamation}&name=${serviceNameChoisi}&isDepartment=false`;
            }

            if (departementNameChoisi !== "" || serviceNameChoisi !== "") {
                const response = await api.get(endpoint);
                const dailyComplaints = response?.data?.dailyCounts;

                // Extract totals for each status category
                const days = Object.keys(dailyComplaints);
                const total = days.map(day => dailyComplaints[day]["total"]);
                const afaire = days.map(day => dailyComplaints[day]["A faire"]);
                const encours = days.map(day => dailyComplaints[day]["En cours"]);
                const terminer = days.map(day => dailyComplaints[day]["Terminer"]);

                if (departementNameChoisi !== "") {
                    setReclamationParAgenceParDepartementParMois(total);
                    setReclamationAFaireParAgenceParDepartementParMois(afaire);
                    setReclamationEnCoursParAgenceParDepartementParMois(encours);
                    setReclamationTerminerParAgenceParDepartementParMois(terminer);
                } else if (serviceNameChoisi !== "") {
                    setReclamationParAgenceParServiceParMois(total);
                    setReclamationAFaireParAgenceParServiceParMois(afaire);
                    setReclamationEnCoursParAgenceParServiceParMois(encours);
                    setReclamationTerminerParAgenceParServiceParMois(terminer);
                }
            }
        }
    }

    useEffect(()=>{
        if(agenceID){
            handleInformation();
        }
    }, [choisirAnneeReclamation, departementNameChoisi, serviceNameChoisi, choisirMoisReclamation, agenceID])

    let dataSetsDepSer = [];
    let total = [];
    if (agenceNameChoisi !== "" && choisirAnneeReclamation !== undefined && choisirMoisReclamation === undefined) {
        if(departementNameChoisi !== ""){
            total = reclamationParAgencParDepartementeParYear; 
            dataSetsDepSer = [
                {
                    label: "Total Réclamation Par année - A faire",
                    color: "warning",
                    data: reclamationAFaireParAgencParDepartementeParYear,
                },
                {
                    label: "Total Réclamation Par année - En cours",
                    color: "info",
                    data: reclamationEnCoursParAgencParDepartementeParYear,
                },
                {
                    label: "Total Réclamation Par année - Terminer",
                    color: "success",
                    data: reclamationTerminerParAgencParDepartementeParYear,
                }
            ];
        }else if(serviceNameChoisi !== ""){
            total = reclamationParAgencParServiceParYear; 
            dataSetsDepSer = [
                {
                    label: "Total Réclamation Par année - A faire",
                    color: "warning",
                    data: reclamationAFaireParAgencParServiceParYear,
                },
                {
                    label: "Total Réclamation Par année - En cours",
                    color: "info",
                    data: reclamationEnCoursParAgencParServiceParYear,
                },
                {
                    label: "Total Réclamation Par année - Terminer",
                    color: "success",
                    data: reclamationTerminerParAgencParServiceParYear,
                }
            ];
        }
        
    } else if (agenceNameChoisi !== "" && choisirAnneeReclamation !== undefined && choisirMoisReclamation !== undefined) {
        if(departementNameChoisi !== ""){
            total = reclamationParAgencParDepartementeParMois; 
            dataSetsDepSer = [
                {
                    label: "Total Réclamation Par Mois - A faire",
                    color: "warning",
                    data: reclamationAFaireParAgencParDepartementeParMois,
                },
                {
                    label: "Total Réclamation Par Mois - En cours",
                    color: "info",
                    data: reclamationEnCoursParAgencParDepartementeParMois,
                },
                {
                    label: "Total Réclamation Par Mois - Terminer",
                    color: "success",
                    data: reclamationTerminerParAgencParDepartementeParMois,
                }
            ];
        }else if(serviceNameChoisi !== ""){
            total = reclamationParAgencParServiceParMois; 
            dataSetsDepSer = [
                {
                    label: "Total Réclamation Par Mois - A faire",
                    color: "warning",
                    data: reclamationAFaireParAgencParServiceParMois,
                },
                {
                    label: "Total Réclamation Par Mois - En cours",
                    color: "info",
                    data: reclamationEnCoursParAgencParServiceParMois,
                },
                {
                    label: "Total Réclamation Par Mois - Terminer",
                    color: "success",
                    data: reclamationTerminerParAgencParServiceParMois,
                }
            ];
        }
    }

    return { 
        dataSetsDepSer,
        total
    }
}