import { useEffect, useRef, useState } from "react";
import api from "utils/axios";

export default function useFetchStatut() {
  const [loading, setLoading] = useState(false);
  const [afficheFilter, setAfficheFilter] = useState(true);
  const [data, setData] = useState([]);
  const myRef = useRef(null);


  const handleFetch = async () => {
    setLoading(true);
    try {
      const response = await api.get("/statut");
      setData(response?.data?.data);
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    document.title = "Statut";
    handleFetch();
  }, []);
  return {
    loading,afficheFilter,data,myRef,handleFetch
  }
}