import React, { lazy, Suspense } from 'react';
const AjouterTicket = lazy(() => import("pages/ticket/AjouterTicket"));
const KanbanTickets = lazy(() => import('pages/ticket/kanban'));
const UpdateTicket = lazy(() => import('pages/ticket/UpdateTicket'));
const ListTickets = lazy(() => import('pages/ticket/ListeTicket'));
const AddUser = lazy(() => import("pages/users/AddUser"));
const UpdateUser = lazy(() => import("pages/users/UpdateUser"));
const VoirTicket = lazy(() => import('pages/ticket/VoirTicket'));
const HistoriqueTickets = lazy(() => import('pages/ticket/Historique/HistoriqueTiquets'));

import LoadingAnimation from "components/LoadingAnimation";

const routes = [
  {
    name: "Ajouter Ticket",
    key: "ajouterTicket",
    route: "/reclamation/ajouterTicket/",
    component: <Suspense fallback={<LoadingAnimation />}><AjouterTicket /></Suspense>,
  },

  {
    name: "Ajouter Ticket",
    key: "ajouterTicket",
    route: "/reclamation/ajouterTicket/:agenceID",
    component: <Suspense fallback={<LoadingAnimation />}><AjouterTicket /></Suspense>,
  },

  {
    name: "Update Ticket",
    key: "modifierTicket",
    route: "/reclamation/modifierTicket/:tiquetID",
    component: <Suspense fallback={<LoadingAnimation />}><UpdateTicket /></Suspense>,
  },

  {
    name: "Voir Ticket",
    key: "voirTicket",
    route: "/reclamation/voirTicket/:tiquetID",
    component: <Suspense fallback={<LoadingAnimation />}><VoirTicket /></Suspense>,
  },
  {
    name: "Liste Tickets",
    key: "listeTickets",
    route: "/reclamation/listTickets/:agenceID",
    component: <Suspense fallback={<LoadingAnimation />}><ListTickets /></Suspense>,
  },

  {
    name: "kanban Tickets",
    key: "kanbanTickets",
    route: "/reclamation/kanbanTickets/:agenceID",
    component: <Suspense fallback={<LoadingAnimation />}><KanbanTickets /></Suspense>,
  },

  {
    name: "Historique Tickets",
    key: "listTickets",
    route: "/historique/listTickets/:agenceID",
    component: <Suspense fallback={<LoadingAnimation />}><HistoriqueTickets /></Suspense>,
  },
//users
{
  route: "/users/add-user",
  component: <Suspense fallback={<LoadingAnimation />}><AddUser /></Suspense>,
  key: "add-user"
},
{
  route: "/users/:id",
  component: <Suspense fallback={<LoadingAnimation />}><UpdateUser /></Suspense>,
  key: "update-user"
},



];



export default routes;
