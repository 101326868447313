import { useEffect, useState } from "react";
import api from "utils/axios";

export default function useReclamationParAgence({ choisirAnneeReclamation, setChoisirAnneeReclamation, choisirMoisReclamation, setChoisirMoisReclamation,agenceNameChoisi, agenceID}) {

    //array return le total de raclamation par agence par year
    const [reclamationTotalParAgenceParYear, setReclamationTotalParAgenceParYear] = useState([]);
    const [aFairereclamationParAgenceParYear, setAFaireReclamationParAgenceParYear] = useState([]);
    const [enCoursreclamationParAgenceParYear, setEnCoursReclamationParAgenceParYear] = useState([]);
    const [terminerreclamationParAgenceParYear, setTerminerReclamationParAgenceParYear] = useState([]);
    //exemple [0,0,0,0,...] qui est [Jan, Fev, Mars, ...]

    // array return le total de reclamation par agence par mois
    const [reclamationTotalParAgenceParMois, setReclamationTotalParAgenceParMois] = useState([]);    //to connaitre le pourcentage des reclamations terminer par agence dans une année
    const [aFairereclamationParAgenceParMois, setAFaireReclamationParAgenceParMois] = useState([]);
    const [enCoursreclamationParAgenceParMois, setEnCoursReclamationParAgenceParMois] = useState([]);
    const [terminerreclamationParAgenceParMois, setTerminerReclamationParAgenceParMois] = useState([]);
    //exemple [0,0,0,0,...] qui est [day1, day2, day3, ...]

    const handleInformation = async () => {
        if(choisirAnneeReclamation !== undefined && choisirMoisReclamation === undefined){
            const endpoint = `kpi/getMonthlyComplaints?agenceID=${agenceID}&year=${choisirAnneeReclamation}`;
            const response = await api.get(endpoint);
            // Extracting the total values into an array
            const monthlyComplaints = response?.data?.monthlyComplaints;

            // Extract totals for each status category
            const months = Object.keys(monthlyComplaints);
            const total = months.map(month => monthlyComplaints[month]["total"]);
            const afaire = months.map(month => monthlyComplaints[month]["A faire"]);
            const encours = months.map(month => monthlyComplaints[month]["En cours"]);
            const terminer = months.map(month => monthlyComplaints[month]["Terminer"]);
            
            setReclamationTotalParAgenceParYear(total);
            setAFaireReclamationParAgenceParYear(afaire);
            setEnCoursReclamationParAgenceParYear(encours);
            setTerminerReclamationParAgenceParYear(terminer);

        }else if(choisirAnneeReclamation !== undefined && choisirMoisReclamation !== undefined){
            const endpoint = `kpi/getDailyComplaints?agenceID=${agenceID}&year=${choisirAnneeReclamation}&month=${choisirMoisReclamation}`;
            const response = await api.get(endpoint);
            // Extracting the total values into an array
            const dailyComplaints = response?.data?.dailyComplaints;

            // Extract totals for each status category
            const days = Object.keys(dailyComplaints);
            const total = days.map(day => dailyComplaints[day]["total"]);
            const afaire = days.map(day => dailyComplaints[day]["A faire"]);
            const encours = days.map(day => dailyComplaints[day]["En cours"]);
            const terminer = days.map(day => dailyComplaints[day]["Terminer"]);

            setReclamationTotalParAgenceParMois(total);
            setAFaireReclamationParAgenceParMois(afaire);
            setEnCoursReclamationParAgenceParMois(encours);
            setTerminerReclamationParAgenceParMois(terminer);
        }
    }

    useEffect(()=>{
        if(agenceID){
            handleInformation();
        }
    }, [choisirAnneeReclamation, choisirMoisReclamation, agenceID])
    
    const [percentageReclamationAgenceTerminer, setPercentageReclamationAgenceTerminer] = useState("0%")
    const [nbReclamationRouvertParAgence, setNbReclamationRouvertParAgence] = useState(0)
    const [averageResolutionTimeParAgence, setAverageResolutionTimeParAgence] = useState("0d 0h 0min")
    
    const handleInformationAgence = async () => {
        const endpoint = `kpi/MiniStatAgence?agenceID=${agenceID}`;
        const response = await api.get(endpoint);
        setPercentageReclamationAgenceTerminer(response?.data?.porcentage?.resolvedPercentage);
        setNbReclamationRouvertParAgence(response?.data?.reouvert?.reopenedCount);
        setAverageResolutionTimeParAgence(response?.data?.Average);
    }

    const [titres, setTitres] = useState([]);
    const [allData, setAllData] = useState({})
    const [ticketNameChoisi, setTicketNameChoisi] = useState(undefined);
    const [countTicketRepetedChoisi, setCountTicketRepeatedChoisit] = useState(0);
    const [idTicketsRepeter, setIdTicketsRepeter] = useState([])
    const handleRepetedTicketsParAgenceParTitre = async () => {
        const endpoint = `kpi/getRepeatedTiquets?agenceID=${agenceID}`;
        const response = await api.get(endpoint);
        const data = response?.data;
        if (data) {
            // Extract the keys (e.g., "grgrgrgr", "dsdsdsdsd")
            const titresKeys = Object.keys(data);

            // Set the keys in the 'titres' state and the full data in 'allData'
            setTitres(titresKeys);
            setAllData(data);
        }
    }

    useEffect(()=>{
        if(agenceID){
            handleInformationAgence();
            handleRepetedTicketsParAgenceParTitre();
        }
    }, [agenceID]);

    useEffect(() => {
        if (ticketNameChoisi && allData[ticketNameChoisi]) {
            // Extract the count and ticket numbers for the selected ticket name
            const selectedTicketData = allData[ticketNameChoisi];
            setCountTicketRepeatedChoisit(selectedTicketData.count);
            setIdTicketsRepeter(selectedTicketData.tiquetNumbers);
        }
    }, [ticketNameChoisi, allData]);

    let dataSets = [];
    let totalData = [];
    if (agenceNameChoisi !== "" && choisirAnneeReclamation !== undefined && choisirMoisReclamation === undefined) {
        totalData = reclamationTotalParAgenceParYear; 
        dataSets = [
            {
                label: "Total Réclamation Par année - A faire",
                color: "warning",
                data: aFairereclamationParAgenceParYear,
            },
            {
                label: "Total Réclamation Par année - En cours",
                color: "info",
                data: enCoursreclamationParAgenceParYear,
            },
            {
                label: "Total Réclamation Par année - Terminer",
                color: "success",
                data: terminerreclamationParAgenceParYear,
            }
        ];
    } else if (agenceNameChoisi !== "" && choisirAnneeReclamation !== undefined && choisirMoisReclamation !== undefined) {
        totalData = reclamationTotalParAgenceParMois;
        dataSets = [
            {
                label: "Total Réclamation Par Mois - A faire",
                color: "warning",
                data: aFairereclamationParAgenceParMois,
            },
            {
                label: "Total Réclamation Par Mois - En cours",
                color: "info",
                data: enCoursreclamationParAgenceParMois,
            },
            {
                label: "Total Réclamation Par Mois - Terminer",
                color: "success",
                data: terminerreclamationParAgenceParMois,
            }
        ];
    }

    return { 
        averageResolutionTimeParAgence,
        nbReclamationRouvertParAgence,
        percentageReclamationAgenceTerminer,
        titres,
        ticketNameChoisi, setTicketNameChoisi,
        countTicketRepetedChoisi,
        idTicketsRepeter,
        dataSets,
        totalData
    }
}