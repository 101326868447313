import React from 'react';
import Icon from '@mui/material/Icon';
import Tooltip from '@mui/material/Tooltip';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
} from '@mui/material';
import SoftInput from 'components/SoftInput';
import SoftButton from 'components/SoftButton';
import CloseIcon from '@mui/icons-material/Close';
import LoadingBackdrop from 'utils/LoadingBackdrop';
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import PropTypes from 'prop-types';
import useStatutActions from '../hooks/useStatutActions';
import VisibilityIcon from '@mui/icons-material/Visibility';

function StatutActions({ statut, handleFetch}) {
  const {
    open,
    setOpen,
    titre,
    setName,
    loading,
    errorMessage,
    handleUpdate,
    handleClose,
    showAlert,
  } = useStatutActions(statut, handleFetch);


  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleUpdate();
    }
  };

  return (
    <SoftBox display="flex" alignItems="center">
      <LoadingBackdrop open={loading} />
      
      <SoftBox mx={2}>
        <SoftTypography variant="body1" color="purple" sx={{ cursor: 'pointer', lineHeight: 0 }}>
          <Tooltip title="Modifier" placement="top">
            <Icon onClick={() => setOpen(true)} id="edit-enterprise-page">
              edit
            </Icon>
          </Tooltip>
          <Dialog fullWidth open={open} onKeyDown={handleKeyPress}>
            <DialogTitle>Modifier statut</DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
                '&:hover': {
                  backgroundColor: (theme) => theme.palette.grey[200],
                },
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent>
              <DialogContentText>
                Vous devez saisir le nouveau nom de votre statut.
              </DialogContentText>
              <Grid>
                <SoftTypography variant="caption" fontWeight="bold" color="text">
                  Nom du statut*
                </SoftTypography>
                <Grid
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                  }}
                >
                  <SoftInput
                    name="titre"
                    margin="normal"
                    id="titre"
                    label="statut"
                    type="text"
                    fullWidth
                    placeholder="Nom de statut"
                    inputProps={{
                      minLength: 2,
                      maxLength: 50,
                    }}
                    variant="standard"
                    value={titre}
                    onChange={(e) => setName(e.target.value)}
                    error={titre.trim().length < 2}
                    success={titre.trim().length >= 2}
                    autoFocus
                  />
                  {titre.trim().length < 2 && (
                    <SoftTypography m={0.75} variant="caption" color="error">
                      {errorMessage}
                    </SoftTypography>
                  )}
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <SoftBox mt={2} width="100%" display="flex" justifyContent="space-between">
                <SoftButton onClick={handleClose} sx={{ mt: 2 }} size="small" variant="outlined" color="dark">
                  Annuler
                </SoftButton>
                <SoftButton
                  onClick={handleUpdate}
                  sx={{ mt: 2 }}
                  variant="gradient"
                  color="customized"
                  size="small"
                >
                  Modifier
                </SoftButton>
              </SoftBox>
            </DialogActions>
          </Dialog>
        </SoftTypography>
      </SoftBox>
      <SoftTypography variant="body1" color="purple" sx={{ cursor: 'pointer', lineHeight: 0 }}>
        <Tooltip title="Supprimer" placement="left">
          <Icon id="delete-enterprise" onClick={showAlert}>
            delete
          </Icon>
        </Tooltip>
      </SoftTypography>
    </SoftBox>
  );
}

StatutActions.propTypes = {
  statut: PropTypes.object.isRequired,
  handleFetch: PropTypes.func.isRequired,

};

export default StatutActions;
