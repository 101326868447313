import Stack from '@mui/material/Stack';
import "./loader.css"

export default function LinearColor() {
  return (
    <Stack sx={{ width: '100%', color: 'grey.500', height: '95vh', display: 'flex', justifyContent: 'center' }}>
      {/*  <LinearProgress color="secondary" />
      <LinearProgress color="info" />
      <LinearProgress color="error" />
      <LinearProgress color="success" />
      <LinearProgress color="inherit" /> */}
      <div className="loader"></div>
    </Stack>
  );
};
