import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Typography from '@mui/material/Typography';
import { useTheme } from "@emotion/react";
import { Box } from "@mui/material";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftSelect from "components/SoftSelect";

function MultipleStatisticsCard({ bgColor, stats, direction, namesAgence , agenceNameChoisi , setAgenceNameChoisi, agenceID, setAgenceID, stat2, titres, ticketNameChoisi, setTicketNameChoisi, idTicketsRepeter  }) {
    const theme = useTheme();
  
    return (
      <Card sx={{ overflow: "visible", zIndex: 0 }}>
        <SoftBox bgColor={bgColor} variant="gradient" sx={{ borderRadius: "50px" }}>
          <SoftBox p={3}>
          {
                namesAgence &&
                  <Grid item xs={6} sx={{ display:"block", mb:"12px", mr:"16px", position: "relative", zIndex:5 }}>
                    <SoftTypography  variant="body" color="text" sx={{ fontSize: "12px", pr: "12px" }} >
                      Nom Agence
                    </SoftTypography>
                    <SoftSelect
                      placeholder="Choissiser un agence"
                      options={namesAgence}
                      size="small"
                      value={agenceNameChoisi ?{ label: agenceNameChoisi, value: agenceID }: null}
                      onChange={(e)=>{
                        setAgenceNameChoisi(e.label)
                        setAgenceID(e.value)
                      }}
                    />
                  </Grid>
              }
            <Grid container alignItems="center" justifyContent="center">
                <SoftBox lineHeight={1} textAlign="center">
                  <Grid container spacing={3} justifyContent="center">
                    {stats.map((stat, index) => (
                      <Grid item key={index} xs={12} my="16px">
                        <SoftBox textAlign="center">
                          <SoftBox
                            variant="gradient"
                            bgColor={bgColor === "white" ? stat.icon.color : "white"}
                            color={bgColor === "dark" ? "dark" : "white"}
                            width="3rem"
                            height="3rem"
                            borderRadius="md"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            shadow="md"
                            margin="auto"
                          >
                            <Icon fontSize="small" color="inherit">
                              {stat.icon.component}
                            </Icon>
                          </SoftBox>
                          <SoftTypography variant="button" color={theme.palette.text.primary} fontWeight="bold" mt={2}>
                            {stat.title}
                          </SoftTypography>
                          <SoftTypography variant="h5" fontWeight="bold" color={bgColor === "white" ? "dark" : "white"} mt={1}>
                            {stat.count}
                          </SoftTypography>
                        </SoftBox>
                      </Grid>
                    ))}
                  </Grid>
                </SoftBox>
              </Grid>
              </SoftBox>
              {
                stat2 &&
                  <>
                    <Grid item  xs={6} sx={{ display:"block", mb:"12px", ml: "24px", position: "relative", zIndex:3 }}>
                        <SoftTypography  variant="body" color="text" sx={{ fontSize: "12px", pr: "12px" }} >
                            Titre Ticket
                        </SoftTypography>
                        <SoftSelect
                        placeholder="Choissiser un ticket"
                        options={titres.map((titre)=>{
                            return {
                            label: titre,
                            value: titre
                            }
                        })}
                        size="small"
                        value={ticketNameChoisi ?{ label: ticketNameChoisi, value: ticketNameChoisi }: null}
                        onChange={(e)=>{
                            setTicketNameChoisi(e.label)
                        }}
                        />
                    </Grid>
                    <SoftBox lineHeight={1} textAlign="center">
                    <Grid container spacing={3} justifyContent="center" mb={idTicketsRepeter?.length > 0 ? "26px" : "55px"}>
                        <Grid item xs={12} my="16px">
                            <SoftBox textAlign="center">
                                <SoftBox
                                    variant="gradient"
                                    bgColor={bgColor === "white" ? stat2.icon.color : "white"}
                                    color={bgColor === "dark" ? "dark" : "white"}
                                    width="3rem"
                                    height="3rem"
                                    borderRadius="md"
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    shadow="md"
                                    margin="auto"
                                >
                                    <Icon fontSize="small" color="inherit">
                                    {stat2.icon.component}
                                    </Icon>
                                </SoftBox>
                                <SoftTypography variant="button" color={theme.palette.text.primary} fontWeight="bold" mt={2}>
                                    {stat2.title}
                                </SoftTypography>
                                <SoftTypography variant="h5" fontWeight="bold" color={bgColor === "white" ? "dark" : "white"} mt={1}>
                                    {stat2.count}
                                </SoftTypography>
                                <SoftTypography
                                    variant="h5"
                                    fontWeight="bold"
                                    color={bgColor === "white" ? "dark" : "white"}
                                    sx={{ whiteSpace: "wrap" }}
                                >
                                    {idTicketsRepeter.join(", ")}
                                </SoftTypography>
                            
                            </SoftBox>
                        </Grid>
                    </Grid>
                    </SoftBox>
                  </>
              }
        </SoftBox>
      </Card>
    );
  }
  

// Setting default values for the props of MultipleStatisticsCard
MultipleStatisticsCard.defaultProps = {
  bgColor: "white",
  direction: "right",
};

// Typechecking props for the MultipleStatisticsCard
MultipleStatisticsCard.propTypes = {
    bgColor: PropTypes.oneOf([
      "white",
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
    ]),
    stats: PropTypes.arrayOf(
      PropTypes.shape({
        icon: PropTypes.shape({
          color: PropTypes.oneOf([
            "primary",
            "secondary",
            "info",
            "success",
            "warning",
            "error",
            "dark"
          ]).isRequired,
          component: PropTypes.node.isRequired,
        }).isRequired,
        title: PropTypes.string.isRequired,
        count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      })
    ).isRequired,
    stat2: PropTypes.shape({
        icon: PropTypes.shape({
          color: PropTypes.oneOf([
            "primary",
            "secondary",
            "info",
            "success",
            "warning",
            "error",
            "dark"
          ]).isRequired,
          component: PropTypes.node.isRequired,
        }).isRequired,
        title: PropTypes.string.isRequired,
        count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    }),
    direction: PropTypes.oneOf(["right", "left"]),
    namesAgence : PropTypes.arrayOf(PropTypes.string),
    agenceNameChoisi : PropTypes.string,
    setAgenceNameChoisi : PropTypes.func,
    agenceID : PropTypes.string,
    setAgenceID : PropTypes.func,

    titres : PropTypes.arrayOf(PropTypes.string),
    ticketNameChoisi : PropTypes.string,
    setTicketNameChoisi : PropTypes.func,
    idTicketsRepeter: PropTypes.arrayOf(PropTypes.string),
  };
  

export default MultipleStatisticsCard;
