const isValidPhone = (phone) => {
  // Allow only numbers, '+' or '-'
  const phoneRegex = /^[0-9+]+$/;
  return phoneRegex.test(phone);
};
const isValidName = (Name) => {
  // Allow only strings (text)
  const nameRegex = /^[\p{L}]{2,30}$/u;
  return nameRegex.test(Name);
};
const isValidBankName = (Name) => {
  // Allow only strings (text)
  const nameRegex = /^[\p{L},']{2,30}$/u;
  return nameRegex.test(Name);
};
const isValidNameWithSpace = (Name) => {
  // Allow only strings (text)
  const nameRegex = /^(?!.*\s\s)[\p{L}\s]{2,30}$/u;

  return nameRegex.test(Name);
};
const isValidNumber = (input) => {
  // Allow only numbers
  const NumberRegex = /^[0-9]+$/;
  return NumberRegex.test(input);
};
const isValidDecimal = (value) => {
  // Allow numbers with decimal point
  const decimalRegex = /^\d+(\.\d+)?$/;
  return decimalRegex.test(value);
};

const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,50}$/;
const isValidEmail = (email) => {
  // Implement your email validation logic here
  // This is a basic example, you can use a library or more complex validation
  return emailRegex.test(email);
};


const isValidBirthday = (birthday) => {
  // Implement your birthday validation logic here
  // This is a basic example, you can adjust it based on your specific requirements

  // Define the regex pattern for validating the date in the format "yyyy-mm-dd"
  const dateRegex = /^(19|20)\d{2}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/;

  // Check if the birthday matches the regex pattern
  if (!dateRegex.test(birthday)) {
    return false; // Invalid format
  }

  // Extract the year, month, and day from the birthday string
  const [year, month, day] = birthday.split("-");

  // Convert the extracted parts to numbers
  const yearNum = parseInt(year, 10);
  const monthNum = parseInt(month, 10);
  const dayNum = parseInt(day, 10);

  // Perform additional validation
  if (yearNum < 1900 || yearNum > 2023) {
    return false; // Invalid year range
  }

  if (monthNum < 1 || monthNum > 12) {
    return false; // Invalid month range
  }

  // Check if dayNum is within the valid range for the given month
  const maxDay = new Date(yearNum, monthNum, 0).getDate();
  if (dayNum < 1 || dayNum > maxDay) {
    return false; // Invalid day range
  }

  // Return true if the birthday is valid
  return true;
};

const isValidSocialMediaLink = (link) => {
  // Define the regex pattern for validating URLs
  const urlRegex = /^(https?:\/\/)?([\w-]+\.)+[\w-]+(\/[\w-./?%&=]*)?$/;

  // Check if the URL matches the regex pattern
  return urlRegex.test(link);
};
const isValidAlphanumeric = (input) => {
  // Define the regex pattern for validating alphanumeric strings (including spaces)
  const alphanumericRegex = /^[\p{L}\p{N}\s]+$/u;
  // Check if the input matches the regex pattern
  return alphanumericRegex.test(input);
};
const isValidSerialNumber = (input) => {
  const SerialNumberRegex = /^([a-zA-Z0-9/]{8,16})$/;
  return SerialNumberRegex.test(input);
};
const isValidWebSite = (input) => {
  const websiteRegex = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)?[a-zA-Z0-9]+\.[a-zA-Z]{2,7}(\/\S*)?$/;

  return websiteRegex.test(input);
};
const isVaildAdress = (input) => {
  const addressRegex = /^[\p{L}0-9\s.,/]+$/u;
  return addressRegex.test(input);
};
const passwordRegEx =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=~`+[{\]}\\|:;"'<,>.?/])[a-zA-Z\d!@#$%^&*()\-_=~`+[{\]}\\|:;"'<,>.?/\s]{8,50}$/;
const isValidPassword = (password) => {
  return passwordRegEx.test(password);
};
export {
  isValidWebSite,
  isValidPhone,
  isValidName,
  isValidNumber,
  isValidEmail,
  isValidBirthday,
  isValidSocialMediaLink,
  isValidAlphanumeric,
  isValidSerialNumber,
  isVaildAdress,
  isValidNameWithSpace,
  isValidBankName,
  isValidDecimal,
  isValidPassword,
  passwordRegEx,
  emailRegex
};
