/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/**
 * The base box-shadow styles for the Soft UI Dashboard PRO React.
 * You can add new box-shadow using this file.
 * You can customized the box-shadow for the entire Soft UI Dashboard PRO React using thie file.
 */

// Soft UI Dashboard PRO React Base Styles
import colors from "assets/theme/base/colors";

// Soft UI Dashboard PRO React Helper Functions
import boxShadow from "assets/theme/functions/boxShadow";

const { black, white, info, inputColors, tabs } = colors;

const boxShadows = {
  xs: boxShadow(black.main, 0.15, "", [0, 2], [9, -5]),
  sm: boxShadow(black.main, 0.12, "", [0, 5], [10, 0]),
  md: `${boxShadow(black.light, 0.12, "", [0, 4], [6, -1])}, ${boxShadow(
    black.light,
    0.07,
    "",
    [0, 2],
    [4, -1]
  )}`,
  lg: `${boxShadow(black.light, 0.15, "", [0, 8], [26, -4])}, ${boxShadow(
    black.light,
    0.06,
    "",
    [0, 8],
    [9, -5]
  )}`,
  xl: boxShadow(black.light, 0.25, "", [0, 23], [45, -11]),
  xxl: boxShadow(black.main, 0.05, "", [0, 20], [27, 0]),
  inset: boxShadow(black.main, 0.075, "inset", [0, 1], [2, 0]),
  navbarBoxShadow: `${boxShadow(white.main, 0.9, "inset", [0, 0], [1, 1])}, ${boxShadow(
    black.main,
    0.05,
    "",
    [0, 20],
    [27, 0]
  )}`,
  buttonBoxShadow: {
    main: `${boxShadow(black.main, 0.11, "", [0, 4], [7, -1])}, ${boxShadow(
      black.main,
      0.07,
      "",
      [0, 2],
      [4, -1]
    )}`,
    stateOf: `${boxShadow(black.main, 0.09, "", [0, 3], [5, -1])}, ${boxShadow(
      black.main,
      0.07,
      "",
      [0, 2],
      [5, -1]
    )}`,
    stateOfNotHover: boxShadow(info.main, 0.5, "", [0, 0], [0, 3.2]),
  },
  inputBoxShadow: {
    focus: boxShadow(inputColors.boxShadow, 1, "", [0, 0], [0, 2]),
    error: boxShadow(inputColors.error, 0.6, "", [0, 0], [0, 2]),
    success: boxShadow(inputColors.success, 0.6, "", [0, 0], [0, 2]),
  },
  sliderBoxShadow: {
    thumb: boxShadow(black.main, 0.2, "", [0, 1], [13, 0]),
  },
  tabsBoxShadow: {
    indicator: boxShadow(tabs.indicator.boxShadow, 1, "", [0, 1], [5, 1]),
  },
};

export default boxShadows;
