import api from "./axios";

export default async function handleLogout(navigate) {
  const refreshToken =
    localStorage.getItem("refreshToken") || sessionStorage.getItem("refreshToken");
  if (!refreshToken) return navigate("/login");
  await api.delete("/logout", {
    headers: { Authorization: `Basic ${refreshToken}` }
  })
}
