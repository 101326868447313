import useGlobale from "./useGloable";
import useReclamationDepartementService from "./useReclamationDepartementService";
import useReclamationParAgence from "./useReclamationParAgence";

export default function useReturnReclamationParAgence() {

    const {  
        choisirAnneeReclamation, setChoisirAnneeReclamation, 
        choisirMoisReclamation, setChoisirMoisReclamation,
        namesAgence,
        agenceNameChoisi, setAgenceNameChoisi,
        agenceID, setAgenceID,
        departmentsByAgence,
        departementNameChoisi, setDepartementNameChoisi,
        servicesByAgence,
        serviceNameChoisi, setServiceNameChoisi
    } = useGlobale();

    const {
        averageResolutionTimeParAgence,
        nbReclamationRouvertParAgence,
        percentageReclamationAgenceTerminer,
        titres,
        ticketNameChoisi, setTicketNameChoisi,
        countTicketRepetedChoisi,
        idTicketsRepeter,
        dataSets,
        totalData
    } = useReclamationParAgence({ 
        choisirAnneeReclamation,
        setChoisirAnneeReclamation,
        choisirMoisReclamation,
        setChoisirMoisReclamation,
        agenceNameChoisi,
        agenceID
    });

    const {
        dataSetsDepSer,
        total
    }= useReclamationDepartementService({
        choisirAnneeReclamation,
        setChoisirAnneeReclamation,
        choisirMoisReclamation,
        setChoisirMoisReclamation,
        agenceNameChoisi,
        agenceID,
        departementNameChoisi,
        serviceNameChoisi
    });

    return {  
        namesAgence,
        agenceNameChoisi, setAgenceNameChoisi,
        agenceID, setAgenceID,
        departmentsByAgence,
        departementNameChoisi, setDepartementNameChoisi,
        servicesByAgence,
        serviceNameChoisi, setServiceNameChoisi,
        choisirAnneeReclamation, setChoisirAnneeReclamation,
        choisirMoisReclamation, setChoisirMoisReclamation,
        averageResolutionTimeParAgence,
        nbReclamationRouvertParAgence,
        percentageReclamationAgenceTerminer,
        titres,
        ticketNameChoisi, setTicketNameChoisi,
        countTicketRepetedChoisi,
        idTicketsRepeter,
        dataSets,
        totalData,
        dataSetsDepSer,
        total
    }
}