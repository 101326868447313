import { useEffect, useState } from "react";
import api from "utils/axios";

export default function useListUsers() {
    const [currentPage, setCurrentPage] = useState(1);
    const [listUsers, setListUsers] = useState([]);

    const handleUserInformations = async () => {
        try {
            const endpoint = "kpi/getResponsablestats";
            const response = await api.get(endpoint);
            const usersStats = response.data.stats;
            setListUsers(usersStats);
        } catch (error) {
            console.error('Error fetching Users Informations:', error);
        }
    };

    useEffect(() => {
        handleUserInformations();
    }, []);
;


    return { 
        currentPage,
        listUsers
    }
}