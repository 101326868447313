import { useEffect, useState } from "react";
import api from "utils/axios";

export default function useGlobale() {
    const [choisirAnneeReclamation, setChoisirAnneeReclamation] = useState(undefined)
    const [choisirMoisReclamation, setChoisirMoisReclamation] = useState(undefined)

    const [namesAgence, setNamesAgence] = useState([]);
    const [agenceNameChoisi, setAgenceNameChoisi] = useState("");
    const [agenceID, setAgenceID] = useState(undefined);

    const ListAgences = async () => {
        try {
            const endpoint = "agencies";
            const response = await api.get(endpoint);
            const agencies = response.data.data;
            const agencyOptions = agencies.map(agence => ({
                value: agence._id,
                label: agence.name,
            }));
            setNamesAgence(agencyOptions);
        } catch (error) {
            console.error('Error fetching Agences names:', error);
        }
    };

    useEffect(() => {
        document.title = "Dashboard";
        ListAgences();
    }, []);

    const [departmentsByAgence, setDepartmentsByAgence]= useState([])
    const [departementNameChoisi, setDepartementNameChoisi] = useState("");
    const getDepartmentsByAgence = (agenceID) => {
        const endpoint = `tiquets/get-departments-by-agence/${agenceID}`;

        api.get(endpoint).then((response) => {
            setDepartmentsByAgence(response.data.departmentname);
        });
    };
    
    const [servicesByAgence, setServicesByAgence]= useState([])
    const [serviceNameChoisi, setServiceNameChoisi] = useState("");
    const getServicesByAgence = (agenceID) => {
        const endpoint = `tiquets/get-services-by-agence/${agenceID}`;

        api.get(endpoint).then((response) => {
            setServicesByAgence(response.data.servicename);
        });
    };

    useEffect(() => {
        if(agenceID){
            getDepartmentsByAgence(agenceID);
            getServicesByAgence(agenceID);
        }
    }, [agenceID]);


    return { 
        choisirAnneeReclamation, setChoisirAnneeReclamation, 
        choisirMoisReclamation, setChoisirMoisReclamation,
        namesAgence,
        agenceNameChoisi, setAgenceNameChoisi,
        agenceID, setAgenceID,
        departmentsByAgence,
        departementNameChoisi, setDepartementNameChoisi,
        servicesByAgence,
        serviceNameChoisi, setServiceNameChoisi
    }
}