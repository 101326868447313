import React from 'react';
import { Backdrop, CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';

const LoadingBackdrop = ({ open }) => {
  return (
    <Backdrop
      open={open}
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1000 }}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

LoadingBackdrop.propTypes = {
  open: PropTypes.bool.isRequired,
};

export default LoadingBackdrop;
