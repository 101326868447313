import { useState } from "react";
import api from 'utils/axios';
import Swal from "sweetalert2";
import comparerSansEspacesEtCases from "utils/compareEspace";

const useStatutForm = (handleFetch) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [inputs, setInputs] = useState([{ titre: "" }]);

  const handleClickOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);
    setErrorMessage("");
    setInputs([{ titre: "" }]);
  };

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const newInputs = [...inputs];
    newInputs[index][name] = value;
    setInputs(newInputs);
  };

  const handleAddInput = () => {
    setInputs([...inputs, { titre: "" }]);
  };

  const handleRemoveInput = (indexToRemove) => {
    const newInputs = [...inputs];
    if (indexToRemove >= 0 && indexToRemove < newInputs.length) {
      newInputs.splice(indexToRemove, 1);
      setInputs(newInputs);
    }
  };

  const handleAddStatut = async () => {
    try {
      setLoading(true);
      const hasInvalidInputs = inputs.some((input) => input.titre.trim().length < 2);
      if (hasInvalidInputs) {
        setErrorMessage("La longueur minimale requise est de 2 caractères.");
      } else {
        const duplicateName = inputs.find(
          (input, index) =>
            index !== inputs.findIndex((otherInput) => comparerSansEspacesEtCases(otherInput.titre, input.titre))
        );
        if (duplicateName) {
          Swal.fire(
            "Erreur!",
            `Le nom de statut existe déjà : ${duplicateName.titre}. Merci de saisir un autre nom !`,
            "error"
          );
        } else {
          await api.post("statut/many", inputs);
          setOpen(true);
          Swal.fire("Success!", "statut ajoutée avec succès", "success").then(() => {
            setErrorMessage("");
            setInputs([{ titre: "" }]);
            handleFetch();
            setOpen(false);
          });
        }
      }
    } catch (error) {
      Swal.fire("Erreur!", error.response.data.message, "error");
    } finally {
      setLoading(false);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleAddStatut();
    }
  };

  return {
    errorMessage,
    open,
    loading,
    inputs,
    handleClickOpen,
    handleClose,
    handleInputChange,
    handleAddInput,
    handleRemoveInput,
    handleAddStatut,
    handleKeyPress,
    setInputs
  };
};

export default useStatutForm;
