// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftBadgeDot from "components/SoftBadgeDot";
import PieChart from "examples/Charts/PieChart";
import PropTypes from "prop-types";
import { Box, Menu, MenuItem, Typography } from "@mui/material";
import { useTheme } from "@emotion/react";
import { useState } from "react";
import SoftSelect from "components/SoftSelect";


function PieCharte({ PieCharteData, date, onChangeDate, title, namesAgence , agenceNameChoisi , setAgenceNameChoisi, agenceID, setAgenceID }) {
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenuItemClick = (newValue) => {
    onChangeDate(newValue); // Call the onChangeDate callback with the new value
    handleClose();
  };

  return (
    <Card sx={{ overflow: "visible" }}>
      <SoftBox p={2} pb="0px">
        {
          namesAgence &&
            <Grid item xs={12} sx={{ display:"flex", alignItems:"center", mb:"16px" }}>
              <SoftTypography  variant="body" color="text" sx={{ fontSize: "12px", pr: "12px" }} >
                Nom Agence
              </SoftTypography>
              <SoftSelect
                placeholder="Choissiser un agence"
                options={namesAgence}
                size="small"
                value={agenceNameChoisi ?{ label: agenceNameChoisi, value: agenceID }: null}
                onChange={(e)=>{
                  setAgenceNameChoisi(e.label)
                  setAgenceID(e.value)
                }}
              />
            </Grid>
        }
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <SoftTypography
            fontWeight="medium"
            fontSize="16px"
            color="black"
          >
              {title}
          </SoftTypography>
          <Typography variant="button" color={theme.palette.text.primary} fontWeight="medium" sx={{ fontSize : "10px" , textTransform : "capitalize" , "&:hover":{cursor:"pointer"} }} onClick={handleClick}>
            {date}
          </Typography>
        </Box>
        <Grid container alignItems="center" pt="16px">
          <Grid item xs={6} height="225px">
            <PieChart chart={PieCharteData} height="100%" />
          </Grid>
          <Grid item xs={6} sx={{mt : "0px"}}>
            <SoftBox px={1}>
              {
                PieCharteData.labels.map((label, index) => (
                  <Box key={index} mb={0.5} sx={{ whiteSpace: 'nowrap' }} >
                    <SoftBadgeDot color={PieCharteData.datasets.backgroundColors[index]} size="sm" badgeContent={label} />
                  </Box>
                ))
              }
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => handleMenuItemClick("Année Actuelle")}>Année Actuelle</MenuItem>
        <MenuItem onClick={() => handleMenuItemClick("Mois Actuel")}>Mois Actuel</MenuItem>
        <MenuItem onClick={() => handleMenuItemClick("Jour Actuel")}>Jour Actuel</MenuItem>
      </Menu>
    </Card>
  );
}

PieCharte.propTypes = {
  title: PropTypes.string,
  date: PropTypes.string,
  PieCharteData : PropTypes.shape({
    labels: PropTypes.arrayOf(PropTypes.string),
    datasets: PropTypes.shape({
      backgroundColors: PropTypes.arrayOf(PropTypes.string),
      data: PropTypes.arrayOf(PropTypes.number),
    }),
  }),
  onChangeDate: PropTypes.func,

  namesAgence : PropTypes.arrayOf(PropTypes.string),
  agenceNameChoisi : PropTypes.string,
  setAgenceNameChoisi : PropTypes.func,
  agenceID : PropTypes.string,
  setAgenceID : PropTypes.func,
}


export default PieCharte;
