import { useEffect, useState } from "react";
import api from "utils/axios";

export default function useReclamationGenerale() {
    const [periodeTotalReclamation, setPeriodeTotalReclamation] = useState("Année Actuelle")
    const handleChangePeriodeTotalReclamation = (newValue) => {
      setPeriodeTotalReclamation(newValue); // Update the période value
    };

    const [totalReclamationParJour, setTotalReclamationParJour] = useState(0);
    const [totalReclamationParMois, setTotalReclamationParMois] = useState(0);
    const [totalReclamationParAnnee, setTotalReclamationParAnnee] = useState(0);
    
    const [totalReclamationAFaireParJour, setTotalReclamationAFaireParJour] = useState(0);
    const [totalReclamationAFaireParMois, setTotalReclamationAFaireParMois] = useState(0);
    const [totalReclamationAFaireParAnnee, setTotalReclamationAFaireParAnnee] = useState(0);

    const [totalReclamationEnCoursParJour, setTotalReclamationEnCoursParJour] = useState(0);
    const [totalReclamationEnCoursParMois, setTotalReclamationEnCoursParMois] = useState(0);
    const [totalReclamationEnCoursParAnnee, setTotalReclamationEnCoursParAnnee] = useState(0);

    const [totalReclamationTerminerParJour, setTotalReclamationTerminerParJour] = useState(0);
    const [totalReclamationTerminerParMois, setTotalReclamationTerminerParMois] = useState(0);
    const [totalReclamationTerminerParAnnee, setTotalReclamationTerminerParAnnee] = useState(0);
    
    const [tauxTotalReclamationTerminer, setTauxTotalReclamationTerminer] = useState("0%");
    const handleTotalReclamationInformation = async () => {
        const endpoint = "kpi/get-reclamation";

        const response = await api.get(endpoint);
        const data = response.data;
        setTotalReclamationParJour(data?.today?.total);
        setTotalReclamationParMois(data?.month?.total);
        setTotalReclamationParAnnee(data?.year?.total);  

        setTotalReclamationAFaireParJour(data?.today?.aFaire);
        setTotalReclamationAFaireParMois(data?.month?.aFaire);
        setTotalReclamationAFaireParAnnee(data?.year?.aFaire);

        setTotalReclamationEnCoursParJour(data?.today?.enCours);
        setTotalReclamationEnCoursParMois(data?.month?.enCours);
        setTotalReclamationEnCoursParAnnee(data?.year?.enCours);

        setTotalReclamationTerminerParJour(data?.today?.terminer);
        setTotalReclamationTerminerParMois(data?.month?.terminer);
        setTotalReclamationTerminerParAnnee(data?.year?.terminer);

        setTauxTotalReclamationTerminer(data?.percentage?.resolvedPercentage);
    }
    


    useEffect(()=>{
        handleTotalReclamationInformation();
    }, [])

    return {
        periodeTotalReclamation,
        handleChangePeriodeTotalReclamation,
        totalReclamationParJour, totalReclamationParMois, totalReclamationParAnnee,
        totalReclamationAFaireParJour, totalReclamationAFaireParMois, totalReclamationAFaireParAnnee,
        totalReclamationEnCoursParJour, totalReclamationEnCoursParMois, totalReclamationEnCoursParAnnee,
        totalReclamationTerminerParJour, totalReclamationTerminerParMois, totalReclamationTerminerParAnnee,
        tauxTotalReclamationTerminer,

    }
}