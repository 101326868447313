import { setLogout } from 'context';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from 'utils/axios';
import handleLogout from 'utils/logout';

export const useAuthentication = (dispatch, setBottomAlert) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const isDesktop = () => {
    return typeof window !== 'undefined' && typeof window.__TAURI__ !== 'undefined';
  };

  const handleLoginDesktop = async () => {
    setIsLoading(true);
    try {
      if (isDesktop()) {
        const email = localStorage.getItem("token");
        if (!email) return;

        const response = await api.post("/login-desktop-app", { email });
        const { accessToken, refreshToken } = response.data;

        localStorage.setItem('refreshToken', refreshToken);
        sessionStorage.setItem('refreshToken', refreshToken);
        localStorage.setItem('accessToken', accessToken);

        const userData = atob(accessToken.split('.')[1]);
        const parsedData = JSON.parse(userData);
       
        // Token refresh logic
        const intervalId = setInterval(() => {
          api.post('/auth/refresh-token', {
            refreshToken
          },{
            headers: { Authorization: `Basic ${refreshToken}` },
          }).then((response) => {
            const { refreshToken, accessToken } = response.data;
            const userInfo = atob(accessToken.split('.')[1]);
            setUser(dispatch, { info: JSON.parse(userInfo), accessToken, refreshToken });
            localStorage.setItem('accessToken', accessToken);
          }).catch(async (error) => {
            if (error.code === 'ERR_BAD_REQUEST') {
              await handleLogout(navigate);
              sessionStorage.removeItem('refreshToken');
              localStorage.removeItem('refreshToken');
              navigate('/');
            } else if (error.code === 'ERR_NETWORK') {
              navigate('/error-500');
            }
            clearInterval(intervalId);
          });
        }, 840000);

        const userInfo = atob(accessToken.split('.')[1]);
        setUser(dispatch, { info: JSON.parse(userInfo), accessToken, refreshToken });
        const parsingInfo = JSON.parse(userInfo);

        window.location.href = "/dashboards/default";
      }
    } catch (error) {
      if (error.code === 'ERR_BAD_REQUEST') {
        setBottomAlert({ show: true, message: error.response.data.message });
      } else if (error.code === 'ERR_NETWORK') {
        setBottomAlert({ show: true, message: "erreur côté serveur 500" });
      }
      setIsLoading(false);
      setTimeout(() => setBottomAlert(prevState => ({ ...prevState, show: false })), 10000);
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, handleLoginDesktop, setIsLoading, isDesktop };
};
