const SelectData = {
    mois: [
      { value: "1", label: "Janvier" },
      { value: "2", label: "Février" },
      { value: "3", label: "Mars" },
      { value: "4", label: "Avril" },
      { value: "5", label: "Mai" },
      { value: "6", label: "Juin" },
      { value: "7", label: "Juillet" },
      { value: "8", label: "Août" },
      { value: "9", label: "Septembre" },
      { value: "10", label: "Octobre" },
      { value: "11", label: "Novembre" },
      { value: "12", label: "Décembre" },
    ],
    annee: Array.from({ length: 2070 - 2024 + 1 }, (v, i) => {
      const year = 2024 + i;
      return { value: year.toString(), label: year.toString() };
    }),
    type: [
      { value: "Départment", label: "Départment"},
      { value :"Service" , label:"Service" }
    ]
  };
  export default SelectData;
  