import { useEffect, useState } from "react";
import api from "utils/axios";
import useGlobale from "./useGloable";

export default function useRecPrioriteParAgence({agenceID}) {


    const [prioriteHaute, setPrioriteHaute] = useState(0)
    const [prioriteMoyenne, setPrioriteMoyenne] = useState(0)
    const [prioriteFaible, setPrioriteFaible] = useState(0)

    // console.log(agenceID, ": agenceID");
    
    const handleInformation = async () => {
        const endpoint = `kpi/getComplaintsByPriorityController?agenceID=${agenceID}`;
        const response = await api.get(endpoint);
        // Extracting the total values into an array
        const priorityCounts = response?.data?.stats?.priorityCounts;

        setPrioriteHaute(priorityCounts?.Haute);
        setPrioriteMoyenne(priorityCounts?.Moyenne);
        setPrioriteFaible(priorityCounts?.Faible);
        
    }

    useEffect(()=>{
        if(agenceID){
            handleInformation();
        }
    }, [agenceID])

    return { 
        prioriteHaute,
        prioriteMoyenne,
        prioriteFaible
    }
}