import React from 'react';
import { useLocation } from 'react-router-dom';
import { Fade, Slide } from '@mui/material';
import PropTypes from "prop-types";

const PageTransition = ({ children }) => {
  const location = useLocation();

  return (
    <Fade in={true} timeout={{ enter: 370, exit: 370 }}>
      <Slide direction="left" in={true} timeout={{ enter: 370, exit: 370 }}>
        <div key={location.pathname}>
          {children}
        </div>
      </Slide>
    </Fade>
  );
};

PageTransition.propTypes = {
  children: PropTypes.node.isRequired,
}
export default PageTransition;
