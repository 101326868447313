import React from "react";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import { Grid, IconButton, Stack, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import SoftButton from 'components/SoftButton';
import SoftInput from 'components/SoftInput';
import SoftTypography from 'components/SoftTypography';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import SoftBox from "components/SoftBox";
import LoadingBackdrop from "utils/LoadingBackdrop";
import useStatutForm from "./hooks/useStatutForm";

export default function AddStatut({ handleFetch }) {
  const {
    errorMessage,
    open,
    loading,
    inputs,
    handleClickOpen,
    handleClose,
    handleInputChange,
    handleAddInput,
    handleRemoveInput,
    handleAddStatut,
    handleKeyPress,
     setInputs
  } = useStatutForm(handleFetch);
  

  return (
    <div>
      <LoadingBackdrop open={loading} />
      <SoftButton
        id="add"
        onClick={handleClickOpen}
        variant="gradient"
        color="purple"
        size="small"
        mb={{ xs: 2, sm: 0 }}
      >
        +Ajouter Statut
      </SoftButton>
      <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose} onKeyDown={handleKeyPress}>
        <DialogTitle>Ajouter Statut</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
            "&:hover": {
              backgroundColor: (theme) => theme.palette.grey[200],
            },
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <DialogContentText>
          Vous devez saisir le nom de statut  afin de l&apos;ajouter.
          </DialogContentText>
          {inputs.map((input, index) => (
            <Grid key={index} sx={{ mt: 1 }}>
              <SoftTypography
                component="label"
                variant="caption"
                fontWeight="medium"
                color="text"
                sx={{ mb: 0.5 }}
              >
                Nom de statut*
              </SoftTypography>
              <Grid
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <Grid
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    alignItems: "flex-start",
                    justifyContent: "center",
                  }}
                >
                  <SoftInput
                    name="titre"
                    margin="normal"
                    id="titre"
                    label="statut"
                    type="text"
                    fullWidth
                    placeholder="Nom de statut"
                    inputProps={{
                      minLength: 2,
                      maxLength: 50,
                    }}
                    autoFocus
                    variant="standard"
                    value={input.titre}
                    onChange={(event) => handleInputChange(index, event)}
                    onBlur={() => {
                      const newInputs = [...inputs];
                      newInputs[index].error = input.titre.length < 2;
                      setInputs(newInputs);
                    }}
                    error={input.error && input.titre.trim().length < 2}
                    success={input.titre.trim().length >= 2}
                  />
                  {input.titre.trim().length < 2 && (
                    <Typography m={0.75} variant="caption" color="error">
                      {errorMessage}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} sm={0.5}>
                  {inputs.length > 1 && (
                    <IconButton>
                      <RemoveCircleIcon
                        color="error"
                        fontSize="medium"
                        onClick={() => handleRemoveInput(index)}
                      />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
            </Grid>
          ))}
          <Stack
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{ height: "100%" }}
          >
            <SoftButton
              size="small"
              sx={{
                mt: 2,
                borderWidth: "2px",
                borderStyle: "dashed solid",
                borderColor: "purple ",
              }}
              onClick={handleAddInput}
            >
              +ajouter plus
            </SoftButton>
          </Stack>
        </DialogContent>
        <DialogActions>
          <SoftBox mt={2} width="100%" display="flex" justifyContent="space-between">
            <SoftButton
              onClick={handleClose}
              sx={{ mt: 2 }}
              variant="outlined"
              color="dark"
              size="small"
            >
              Annuler
            </SoftButton>
            <SoftButton
              onClick={handleAddStatut}
              sx={{ mt: 2 }}
              variant="gradient"
              color="purple"
              size="small"
            >
              Ajouter
            </SoftButton>
          </SoftBox>
        </DialogActions>
      </Dialog>
    </div>
  );
}

AddStatut.propTypes = {
  handleFetch: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};
